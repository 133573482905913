import styled from "styled-components";
import "../styles/index.css";

// Used for wrapping a page that needs to show on desktop
export const ResizeForDesktop = styled.div`
  width: ${({ isDesktop }) => (isDesktop ? "50%" : "100%")};
  height: ${({ isDesktop }) => (isDesktop ? "100vh" : "100%")};
  margin: auto;
  display: flex;
  flex-direction: column;
  position: ${({ isDesktop }) => (isDesktop ? "relative" : "fixed")};
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;
// Used for wrapping a page component
export const Screen = styled.div`
  background-color: ${({ t }) => t.color.light_grey.bg};
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  position: ${({ isDesktop }) => (isDesktop ? "relative" : "fixed")};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: ${({ t }) => t.spacing.f3};
  width: ${({ t }) => t.spacing.f3};
`;
export const SpacerXXSmall = styled.div`
  height: ${({ t }) => t.spacing.f2};
  width: ${({ t }) => t.spacing.f2};
`;
export const SpacerXXXSmall = styled.div`
  height: ${({ t }) => t.spacing.f1};
  width: ${({ t }) => t.spacing.f1};
`;
// Used for providing space between components
export const SpacerSmall = styled.div`
  height: ${({ t }) => t.spacing.f4};
  width: ${({ t }) => t.spacing.f4};
`;
// Used for providing space between components
export const SpacerMedium = styled.div`
  height: ${({ t }) => t.spacing.f5};
  width: ${({ t }) => t.spacing.f5};
`;
// Used for providing space between components
export const SpacerLarge = styled.div`
  height: ${({ t }) => t.spacing.f6};
  width: ${({ t }) => t.spacing.f6};
`;
// Used for providing space between components
export const PaddingXSmall = styled.div`
  height: ${({ t }) => t.spacing.f5};
  width: ${({ t }) => t.spacing.f2};
`;
// Used for providing space between components
export const PaddingXXSmall = styled.div`
  height: ${({ t }) => t.spacing.f5};
  width: ${({ t }) => t.spacing.f1};
`;
// Used for providing space between components
export const SpacerXLarge = styled.div`
  height: ${({ t }) => t.spacing.f7};
  width: ${({ t }) => t.spacing.f7};
`;
// Used for providing padding for component
export const PaddingSmall = styled.div`
  height: 100%;
  width: 100%;
  padding: ${({ t }) => t.spacing.f4};
`;
// Used for providing padding for component
export const PaddingMedium = styled.div`
  height: 100%;
  width: 100%;
  padding: ${({ t }) => t.spacing.f5};
`;
// Used for providing padding for component
export const PaddingLarge = styled.div`
  height: 100%;
  width: 100%;
  padding: ${({ t }) => t.spacing.f6};
`;
// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ red }) => (red ? "red" : "none")};
  background-color: ${({ blue }) => (blue ? "blue" : "none")};
  background-color: ${({ green }) => (green ? "green" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;
// Used for providing a wrapper around a component
export const CurvedContentContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  width: 100%;
  background-color: ${({ t }) => t.color.grey.bg};
  overflow: "hidden";
  border-top-left-radius: ${({ t, left }) =>
    left ? t.radius.f5 : t.radius.f0};
  border-top-right-radius: ${({ t, right }) =>
    right ? t.radius.f5 : t.radius.f0};
`;
export const ConcaveContentContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  width: 100%;
  overflow: hidden;
  min-height: 160px;
  bottom: 0%;
  left: 0%;
  right: 0%;
  z-index: 1;
  background: radial-gradient(
    110% 90% at top,
    transparent 50%,
    ${({ bgColor, t }) => (bgColor ? bgColor : t.color.grey.bg)} 0%
  );
`;
export const TextHero = styled.h1`
  color: ${({ t }) => t.color.light.fg[0]};
  font-size: ${({ t }) => t.size.f9};
  text-align: ${({ ta }) => (ta ? ta : "left")};
`;
export const TextDescription = styled.p`
  color: ${({ t }) => t.color.grey.fg[0]};
  font-size: ${({ t }) => t.size.f4};
  font-weight: ${({ t }) => t.weight.f4};
  text-align: ${({ ta }) => (ta ? ta : "left")};
`;
export const TextCaption = styled.p`
  color: ${({ t, color }) => (color ? color : t.color.grey.fg[0])};
  font-size: ${({ t }) => t.size.f3};
  text-align: ${({ ta }) => (ta ? ta : "left")};
`;
export const TextTitle = styled.p`
  color: ${({ t, color }) => (color ? color : t.color.grey.fg[0])};
  font-size: ${({ t }) => t.size.f6};
  text-align: ${({ ta }) => (ta ? ta : "left")};
  font-weight: ${({ bold }) => (bold ? "bold" : "inherit")};
`;
export const TextInputTitle = styled.p`
  color: ${({ t }) => t.color.grey.fg[0]};
  font-size: ${({ t }) => t.size.f3};
  font-weight: ${({ t }) => t.weight.f5};
  text-align: ${({ ta }) => (ta ? ta : "left")};
`;
export const TextError = styled.p`
  color: ${({ t }) => t.color.error.fg[0]};
  font-size: ${({ t }) => t.size.f3};
  font-weight: ${({ t }) => t.weight.f5};
  text-align: ${({ ta }) => (ta ? ta : "left")};
`;
export const TextPill = styled.p`
  color: ${({ t }) => t.color.grey.fg[0]};
  font-size: ${({ t }) => t.size.f1};
  font-family: "MTNBrighterSans-Medium";
  text-align: ${({ ta }) => (ta ? ta : "left")};
`;
export const Divider = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  background: ${({ t }) => t.color.middle_grey.bg};
`;
export const StyledInput = styled.input`
  border: 1px solid ${({ t }) => t.color.light.fg[2]};
  background-color: ${({ t }) => t.color.light.bg};
  border-radius: ${({ t }) => t.radius.f2};
  width: ${({ widthPercent }) => (widthPercent ? widthPercent : "100%")};
  height: 54px;
  padding-left: ${({ t }) => t.size.f5};
  padding-right: ${({ t }) => t.size.f5};
  outline: none;
  font-size: ${({ t }) => t.size.f4};
  font-weight: ${({ t }) => t.weight.f4};
  :focus {
    outline: none !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  }
`;
export const StyledLoader = styled.span`
  width: ${({ primary }) => (primary ? "40px" : "25px")};
  height: ${({ primary }) => (primary ? "40px" : "25px")};
  border: 4px solid #333;
  border-bottom-color: transparent;
  border-radius: 45px;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const StyledScreenOverflow = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  transition: opacity 1s ease 0s;
  z-index: 99;
  visibility: ${({ showOverflow }) => (showOverflow ? "visible" : "hidden")};
`;

export const StyledOverflow = styled.div`
  background-color: #f6f6f6;
  width: 100%;
  border-top-left-radius: ${({ t }) => t.radius.f5};
  overflow: hidden;
  position: absolute;
  transition: all 0.5s ease 0s;
  bottom: ${({ showOverflow }) => (showOverflow ? 0 : "calc(-60px * 4)")};
`;
