import { UserNumber } from "./UserNumber";

export class User {
  constructor({
    access_token = "",
    expires_in = 0,
    refresh_token = "",
    first_name = "",
    last_name = "",
    gender = "",
    email = "",
    address = "",
    primary_number = null,
    alternative_numbers = [],
    currency = "",
    country_code = "",
    user_id = "",
    is_first_onboard = false,
    puk = "",
    plan = "",
    sim_registration_date = "",
    photo_url = "",
  } = {}) {
    this.access_token = access_token;
    this.expires_in = expires_in;
    this.refresh_token = refresh_token;
    this.first_name = first_name;
    this.last_name = last_name;
    this.gender = gender;
    this.email = email;
    this.address = address;
    this.primary_number =
      primary_number != null
        ? new UserNumber(primary_number)
        : new UserNumber();
    this.alternative_numbers =
      alternative_numbers?.length > 0
        ? alternative_numbers?.map((item) => new UserNumber(item))
        : [];
    this.currency = currency;
    this.country_code = country_code;
    this.is_first_onboard = is_first_onboard;
    this.user_id = user_id;
    this.puk = puk;
    this.plan = plan;
    this.sim_registration_date = sim_registration_date;
    this.photo_url = photo_url;
  }
}
