/* istanbul ignore file */
import { OS_PLATFORMS } from "../constants/appConstants";

let nAgt = navigator.userAgent;
let browserName = navigator.appName;
let fullVersion = "" + parseFloat(navigator.appVersion);
let majorVersion = parseInt(navigator.appVersion, 10);
let nameOffset, verOffset, ix;

// In Opera 15+, the true version is after "OPR/"
if ((verOffset = nAgt.indexOf("OPR/")) !== -1) {
  browserName = "Opera";
  fullVersion = nAgt.substring(verOffset + 4);
}
// In older Opera, the true version is after "Opera" or after "Version"
else if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
  browserName = "Opera";
  fullVersion = nAgt.substring(verOffset + 6);
  if ((verOffset = nAgt.indexOf("Version")) !== -1)
    fullVersion = nAgt.substring(verOffset + 8);
}
// In MSIE, the true version is after "MSIE" in userAgent
else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
  browserName = "Microsoft Internet Explorer";
  fullVersion = nAgt.substring(verOffset + 5);
}
// In Chrome, the true version is after "Chrome"
else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
  browserName = "Chrome";
  fullVersion = nAgt.substring(verOffset + 7);
}
// In Safari, the true version is after "Safari" or after "Version"
else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
  browserName = "Safari";
  fullVersion = nAgt.substring(verOffset + 7);
  if ((verOffset = nAgt.indexOf("Version")) !== -1)
    fullVersion = nAgt.substring(verOffset + 8);
}
// In Firefox, the true version is after "Firefox"
else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
  browserName = "Firefox";
  fullVersion = nAgt.substring(verOffset + 8);
}
// In most other browsers, "name/version" is at the end of userAgent
else if (
  (nameOffset = nAgt.lastIndexOf(" ") + 1) < (verOffset = nAgt.lastIndexOf("/"))
) {
  browserName = nAgt.substring(nameOffset, verOffset);
  fullVersion = nAgt.substring(verOffset + 1);
  if (browserName.toLowerCase() === browserName.toUpperCase()) {
    browserName = navigator.appName;
  }
}
// trim the fullVersion string at semicolon/space if present
if ((ix = fullVersion.indexOf(";")) !== -1)
  fullVersion = fullVersion.substring(0, ix);
if ((ix = fullVersion.indexOf(" ")) !== -1)
  fullVersion = fullVersion.substring(0, ix);

majorVersion = parseInt("" + fullVersion, 10);
if (isNaN(majorVersion)) {
  fullVersion = "" + parseFloat(navigator.appVersion);
  majorVersion = parseInt(navigator.appVersion, 10);
}

export function getBrowserName() {
  return browserName;
}

export function getBrowserVersion() {
  return majorVersion.toString();
}

export const getOperatingSystem = () => {
  let OSName = "Unknown OS";
  if (navigator.userAgent.indexOf("Win") !== -1) OSName = "Windows";
  if (navigator.userAgent.indexOf("Mac") !== -1) OSName = "Macintosh";
  if (navigator.userAgent.indexOf("Linux") !== -1) OSName = "Linux";
  if (navigator.userAgent.indexOf("Android") !== -1) OSName = "Android";
  if (navigator.userAgent.indexOf("like Mac") !== -1) OSName = "iOS";
  return OSName;
};

export const getEngine = () => {
  let browserEngine = "";
  //rendering engines
  let engine = {
    ie: 0,
    gecko: 0,
    webkit: 0,
    khtml: 0,
    opera: 0,

    //complete version
    ver: null,
  };

  //browsers
  let browser = {
    //browsers
    ie: 0,
    firefox: 0,
    safari: 0,
    konq: 0,
    opera: 0,
    chrome: 0,

    //specific version
    ver: null,
  };

  //detect rendering engines/browsers
  let ua = navigator.userAgent;
  if (window.opera) {
    engine.ver = browser.ver = window.opera.version();
    engine.opera = browser.opera = parseFloat(engine.ver);
    browserEngine = "Opera";
  } else if (/AppleWebKit\/(\S+)/.test(ua)) {
    engine.ver = RegExp["$1"];
    engine.webkit = parseFloat(engine.ver);
    //figure out if it's Chrome or Safari
    if (/Chrome\/(\S+)/.test(ua)) {
      browser.ver = RegExp["$1"];
      browser.chrome = parseFloat(browser.ver);
      browserEngine = "Chrome";
    } else if (/Version\/(\S+)/.test(ua)) {
      browser.ver = RegExp["$1"];
      browser.safari = parseFloat(browser.ver);
      browserEngine = "AppleWebKit";
    } else {
      //approximate version
      let safariVersion = 1;
      if (engine.webkit < 100) {
        safariVersion = 1;
        browserEngine = "AppleWebKit";
      } else if (engine.webkit < 312) {
        safariVersion = 1.2;
        browserEngine = "AppleWebKit";
      } else if (engine.webkit < 412) {
        safariVersion = 1.3;
        browserEngine = "AppleWebKit";
      } else {
        safariVersion = 2;
        browserEngine = "AppleWebKit";
      }

      browser.safari = browser.ver = safariVersion;
    }
  } else if (/KHTML\/(\S+)/.test(ua) || /Konqueror\/([^;]+)/.test(ua)) {
    engine.ver = browser.ver = RegExp["$1"];
    engine.khtml = browser.konq = parseFloat(engine.ver);
    browserEngine = "KHTML";
  } else if (/rv:([^)]+)\) Gecko\/\d{8}/.test(ua)) {
    engine.ver = RegExp["$1"];
    engine.gecko = parseFloat(engine.ver);
    browserEngine = "Gecko";
    //determine if it's Firefox
    if (/Firefox\/(\S+)/.test(ua)) {
      browser.ver = RegExp["$1"];
      browser.firefox = parseFloat(browser.ver);
      browserEngine = "Firefox";
    }
  } else if (/MSIE ([^;]+)/.test(ua)) {
    // add Trident/ to test IE11, Edge for Edge
    engine.ver = browser.ver = RegExp["$1"];
    engine.ie = browser.ie = parseFloat(engine.ver);
    browserEngine = "IE/Edge";
  }

  return browserEngine;
};

export function isBrowserSupported(platform) {
  switch (platform) {
    case OS_PLATFORMS.ANDROID:
      if (browserName.toLowerCase().includes("chrome") && majorVersion >= 94) {
        return true;
      } else if (
        browserName.toLowerCase().includes("android") &&
        majorVersion >= 94
      ) {
        return true;
      } else if (
        browserName.toLowerCase().includes("firefox") &&
        majorVersion >= 92
      ) {
        return true;
      } else if (
        browserName.toLowerCase().includes("opera") &&
        majorVersion >= 64
      ) {
        return true;
      } else if (
        browserName.toLowerCase().includes("samsung") &&
        majorVersion >= 15
      ) {
        return true;
      } else if (
        browserName.toLowerCase().includes("uc") &&
        majorVersion >= 12
      ) {
        return true;
      } else if (
        browserName.toLowerCase().includes("edge") &&
        majorVersion >= 79
      ) {
        return true;
      } else if (
        browserName.toLowerCase().includes("qq") &&
        majorVersion >= 10
      ) {
        return true;
      } else if (
        browserName.toLowerCase().includes("baidu") &&
        majorVersion >= 7
      ) {
        return true;
      } else {
        return false;
      }
    case OS_PLATFORMS.IOS:
      return browserName.toLowerCase().includes("safari") && majorVersion >= 13;

    case OS_PLATFORMS.MAC_OS:
      if (browserName.toLowerCase().includes("chrome") && majorVersion >= 39) {
        return true;
      } else if (
        browserName.toLowerCase().includes("edge") &&
        majorVersion >= 79
      ) {
        return true;
      } else {
        return false;
      }
    case OS_PLATFORMS.LINUX:
      if (browserName.toLowerCase().includes("chrome") && majorVersion >= 39) {
        return true;
      } else if (
        browserName.toLowerCase().includes("edge") &&
        majorVersion >= 79
      ) {
        return true;
      } else {
        return false;
      }
    case OS_PLATFORMS.WINDOWS:
      if (browserName.toLowerCase().includes("chrome") && majorVersion >= 39) {
        return true;
      } else if (
        browserName.toLowerCase().includes("uc") &&
        majorVersion >= 12
      ) {
        return true;
      } else if (
        browserName.toLowerCase().includes("edge") &&
        majorVersion >= 79
      ) {
        return true;
      } else {
        return false;
      }
    default:
      return false;
  }
}

export function getJSessionId() {
  let jsId = document.cookie.match(/JSESSIONID=[^;]+/);
  if (jsId != null) {
    if (jsId instanceof Array) jsId = jsId[0].substring(11);
    else jsId = jsId.substring(11);
  }
  return jsId;
}

export const isSafariInstalled = () => {
  let ua = window.navigator.userAgent;
  let iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  let webkit = !!ua.match(/WebKit/i);
  let iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

  return iOSSafari && window.navigator.standalone;
};

export function isIOS() {
  const browserInfo = navigator.userAgent.toLowerCase();

  if (browserInfo.match("iphone") || browserInfo.match("ipad")) {
    return true;
  }
  if (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform)
  ) {
    return true;
  }
  return false;
}
