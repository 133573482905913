/* istanbul ignore file */
import React, { useCallback, useContext } from "react";
import { IMAGES } from "../../constants/appConstants";
import ThemeContext from "../../context/themeContext";
import * as s from "../../styles/globalStyles";
import { CurvedButtonContainer } from "../components/ButtonContainers";

const ErrorFallback = ({ bgImage, title, message, actionText, action }) => {
  const { theme: t, isDesktop } = useContext(ThemeContext);

  const performRefresh = () => {
    window.location.href = "/"; //.reload(true);
  };

  const handleAction = useCallback(() => {
    console.log("here");
    if (action) action();
    performRefresh();
  }, [action]);
  return (
    <s.Screen t={t}>
      <s.ResizeForDesktop
        image={bgImage ? bgImage : IMAGES.ErrorBg}
        t={t}
        isDesktop={isDesktop}
      >
        <s.Container flex={1}>
          <s.Container flex={1}>
            <s.Container
              flex={1}
              ai={"center"}
              jc={"center"}
              style={{ padding: t.spacing.f4 }}
            >
              <s.Container
                ai={"center"}
                jc={"center"}
                style={{
                  background: "rgba(255, 255, 255, 0.72)",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.16)",
                  backdropFilter: "blur(5.43656px)",
                  borderRadius: "8px",
                  minHeight: 148,
                  width: "clamp(250px, 100%, 500px)",
                  padding: t.spacing.f4,
                  position: "relative",
                }}
              >
                <img
                  src={IMAGES.Face}
                  alt="girl_avatar"
                  height="88px"
                  style={{
                    position: "absolute",
                    top: "-65px",
                  }}
                />
                <s.TextTitle
                  t={t}
                  ta={"center"}
                  style={{
                    fontSize: t.size.f8,
                    minWidth: 240,
                    maxWidth: 250,
                    fontWeight: t.weight.f7,
                  }}
                >
                  {title ? title : "Well, that was unexpected…"}
                </s.TextTitle>
                <s.SpacerXXSmall t={t} />
                <s.TextCaption
                  t={t}
                  ta={"center"}
                  style={{
                    fontWeight: t.weight.f4,
                    color: t.color.accent.fg[0],
                    lineHeight: "16px",
                    letterSpacing: "0.2px",
                    minWidth: 230,
                    maxWidth: 240,
                  }}
                >
                  {message
                    ? message
                    : "We are experiencing some technical difficulties on our end. Please refresh to try again"}
                </s.TextCaption>
              </s.Container>
            </s.Container>
          </s.Container>

          <CurvedButtonContainer
            t={t}
            left
            style={{ backgroundColor: "white" }}
            title={actionText ? actionText : "Refresh"}
            action={handleAction}
          />
          <s.SpacerLarge t={t} />
        </s.Container>
      </s.ResizeForDesktop>
    </s.Screen>
  );
};

export default ErrorFallback;
