/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: false,
  isError: false,
  lastSubscription: {},
  message: "",
};

const subscriptionSlice = createSlice({
  name: REDUCER.SUBSCRIPTIONS,
  initialState,
  reducers: {
    REQUEST(state) {
      state.loading = true;
    },
    SUCCESS(state, action) {
      state.loading = false;
      state.isError = false;
      state.lastSubscription = action.payload;
      state.message = "";
    },
    FAILED(state, action) {
      state.loading = false;
      state.isError = true;
      state.message = action.payload.message;
    },
    RESET(state) {
      state.loading = false;
      state.isError = false;
      state.lastSubscription = {};
      state.message = "";
    },
  },
});

export const subscriptionActions = subscriptionSlice.actions;

export default subscriptionSlice;
