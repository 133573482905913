/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  platform: "",
  _isPWAInstalled: false,
};

const platformSlice = createSlice({
  name: REDUCER.APP_PLATFORM,
  initialState,
  reducers: {
    GET_PLATFORM(state, action) {
      state.platform = action.payload;
    },
    PWA_INSTALLED_STATUS(state, action) {
      state._isPWAInstalled = action.payload;
    },
  },
});

export const platformActions = platformSlice.actions;
export default platformSlice;
