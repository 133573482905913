/* istanbul ignore file */
import React, { useContext } from "react";
import * as s from "../../styles/globalStyles";
import ThemeContext from "../../context/themeContext";
import { OutlinedButton, Button, TextButton } from "./Buttons";

export const ButtonContainer = ({ children }) => {
  const { theme: t } = useContext(ThemeContext);

  return (
    <s.Container
      style={{
        backgroundColor: t.color.light.bg,
        borderTopRightRadius: t.radius.f7,
      }}
    >
      <s.PaddingMedium t={t}>
        <s.Container fd={"row"}>{children}</s.Container>
      </s.PaddingMedium>
    </s.Container>
  );
};

export const CurvedButtonContainer = ({
  t,
  title,
  action,
  secondaryTitle,
  secondaryAction,
  bgColor,
  loading,
  disabled = false,
  secondaryDisabled = false,
  secondaryLoading = false,
  withInfoComponent,
}) => {
  return (
    <s.Container
      t={t}
      right
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <s.CurvedContentContainer
        right
        t={t}
        style={{ backgroundColor: t.color.grey.fg[1] }}
        ai={"center"}
      >
        {withInfoComponent}
      </s.CurvedContentContainer>
      <s.Container
        style={{ backgroundColor: withInfoComponent && t.color.grey.fg[1] }}
      >
        <s.CurvedContentContainer
          right
          t={t}
          style={{
            backgroundColor: bgColor ? bgColor : t.color.grey.bg,
            boxShadow: `${t.radius.f0} ${t.radius.f1} ${t.radius.f2}  #DDDDDD`,
          }}
        >
          <s.PaddingMedium t={t}>
            <s.Container fd={"row"} jc={"space-between"} flex={1}>
              {secondaryTitle && (
                <>
                  <OutlinedButton
                    title={secondaryTitle}
                    action={secondaryAction}
                    disabled={secondaryDisabled}
                    loading={secondaryLoading}
                  />
                  <s.SpacerMedium t={t} />
                </>
              )}
              {title ? (
                <Button
                  title={title}
                  action={action}
                  loading={loading}
                  disabled={disabled}
                />
              ) : null}
            </s.Container>
          </s.PaddingMedium>
        </s.CurvedContentContainer>
      </s.Container>
    </s.Container>
  );
};

export const TextButtonContainer = ({ c, p, pA, n, nA }) => {
  const { theme: t } = useContext(ThemeContext);
  const textButtonStyle = {
    color: c ? c : t.color.accent.bg,
    textTransform: "capitalize",
    width: "fit-content",
  };
  return (
    <s.Container fd={"row"} jc={"flex-end"}>
      <TextButton
        title={p}
        style={textButtonStyle}
        action={() => {
          pA();
        }}
      />
      <s.SpacerMedium t={t} />
      <TextButton
        title={n}
        style={textButtonStyle}
        action={() => {
          nA();
        }}
      />
    </s.Container>
  );
};
