/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: false,
  isError: false,
  catalog: [],
  recommendedBundles: [],
  listOfProducts: [],
  dataCategories: [],
  errorMessage: "",
};

const productCatalogSlice = createSlice({
  name: REDUCER.PRODUCT_CATALOG,
  initialState,
  reducers: {
    REQUEST(state) {
      state.loading = true;
    },
    SUCCESS(state, action) {
      state.loading = false;
      state.isError = false;
      state.catalog = action.payload.catalog;
      state.recommendedBundles = action.payload.recommendedBundles;
      state.listOfProducts = action.payload.listOfProducts;
      state.dataCategories = action.payload.dataCategories;
      state.errorMessage = "";
    },
    FAILED(state, action) {
      state.loading = false;
      state.isError = true;
      state.errorMessage = action.payload.message;
    },
    CLEAR(state) {
      state.loading = false;
      state.isError = false;
      state.errorMessage = "";
      state.catalog = [];
      state.recommendedBundles = [];
      state.listOfProducts = [];
      state.dataCategories = [];
    },
  },
});

export const productCatalogActions = productCatalogSlice.actions;
export default productCatalogSlice;
