/* istanbul ignore file */
import { holidayActions } from "./holidaySlice";
import { SETUP_CONSTANT } from "../../../config/endPointsConfig";
import {
  API_CONST,
  APP_RESPONSE,
  APP_ROUTES,
  FETCH_OPTIONS,
  HOLIDAY,
} from "../../../constants/appConstants";

const errorMsg = {
  title: "Holiday Offer Error",
  desc: "We could not handle your request at this time. Kindly try again",
};

const showResponse = (history, data) => {
  history.push(APP_ROUTES.REVIEW_PAY_RESPONSE, {
    responseDetails: {
      isError: data.state,
      title: data.title,
      description: data.desc,
      isOtherSuccess: true,
    },
  });
};

const postHolidayOffer = (getAccessTokenSilently, isSubscribing, history) => {
  return async (dispatch) => {
    try {
      dispatch(holidayActions.REQUEST());
      const url = `${SETUP_CONSTANT.endpoints.transaction}subscription`;
      const token = await getAccessTokenSilently();

      if (token == null || token === "") {
        return;
      }

      const body = {
        provider: HOLIDAY.PROVIDER,
        transaction_type: {
          name: isSubscribing ? HOLIDAY.SUBSCRIBE : HOLIDAY.UNSUBSCRIBE,
        },
      };

      const response = await fetch(
        url,
        FETCH_OPTIONS.postData(body, {
          ...API_CONST.X_COUNTRY_CODE,
          ...API_CONST.AUTHORIZATION(token),
        })
      );
      response.json().then((data) => {
        if (response.status !== 200) {
          dispatch(holidayActions.FAILURE(data.message));
          showResponse(history, {
            state: 0,
            title: errorMsg.title,
            desc: errorMsg.desc,
          });
        } else {
          dispatch(
            holidayActions.SUCCESS({
              payload: data,
              isSubscribedToOffer: isSubscribing,
            })
          );
          showResponse(history, {
            state: 1,
            title: APP_RESPONSE.HOLIDAY_SUB_SUCCESS.title,
            desc: APP_RESPONSE.HOLIDAY_SUB_SUCCESS.description,
          });
        }
      });
    } catch (ex) {
      dispatch(holidayActions.FAILURE(ex.message));
      showResponse(history, {
        state: 0,
        title: errorMsg.title,
        desc: errorMsg.desc,
      });
    }
  };
};

const clearHolidayOffer = () => {
  return (dispatch) => {
    dispatch(holidayActions.CLEAR());
  };
};

const changeSubscriptionStatus = (val) => {
  return (dispatch) => {
    dispatch(holidayActions.SUBSCRIPTION_STATUS(val));
  };
};
const changeShowHolidayOfferStatus = (val) => {
  return (dispatch) => {
    dispatch(holidayActions.SHOW_OFFER(val));
  };
};

const getHolidayOffer = () => {
  return async (dispatch) => {
    const url = `${SETUP_CONSTANT.endpoints.frontendCdn}configs/hdae.json`;
    try {
      const response = await fetch(url);
      const responseJson = await response.json();
      if (response.status !== 200) throw new Error(response.statusText);
      dispatch(holidayActions.HOLIDAY_DATA(responseJson));
    } catch {
      dispatch(changeShowHolidayOfferStatus(false));
    }
  };
};

export {
  postHolidayOffer,
  clearHolidayOffer,
  changeSubscriptionStatus,
  changeShowHolidayOfferStatus,
  getHolidayOffer,
};
