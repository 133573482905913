/* istanbul ignore file */
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Auth0ProviderWithHistory from "./pages/login/auth0-provider-with-history";
import "./styles/index.css";
import "./styles/globalStyles.css";
import App from "./App";
import store from "./persistance/redux/store";
import { Provider } from "react-redux";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { serviceWorkerActions } from "./persistance/redux/serviceWorker/serviceWorkerSlice";

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <Auth0ProviderWithHistory>
          <App />
        </Auth0ProviderWithHistory>
      </Router>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onSuccess: () =>
    store.dispatch(serviceWorkerActions.SERVICE_WORKER_INITIALISED()),
  onUpdate: (reg) =>
    store.dispatch(serviceWorkerActions.SERVICE_WORKER_UPDATED(reg)),
});
