import React, { useCallback, useContext } from "react";
import ThemeContext from "../../context/themeContext";
import styled from "styled-components";
import * as s from "../../styles/globalStyles";
import { ICONS } from "../../constants/appConstants";
import { Fab } from "@material-ui/core";

const StyledPrimaryButton = styled.button`
  color: ${({ t, secondary, disabled }) =>
    secondary || disabled ? t.color.primary.bg : t.color.accent.fg[1]};

  background: ${({ t, secondary, loading, disabled }) =>
    loading || disabled
      ? t.color.grey.fg[1]
      : secondary
      ? t.color.light.bg
      : t.color.accent.bg};
  border: 2px solid
    ${({ t, secondary, loading, disabled }) =>
      loading || disabled
        ? t.color.grey.fg[1]
        : secondary
        ? "white"
        : t.color.accent.bg};
  box-sizing: border-box;
  box-shadow: ${({ disabled, noBorder }) =>
    disabled || noBorder ? "none" : "0px 2px 4px rgba(0, 0, 0, 0.16)"};
  border-radius: 24px;
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ t }) => t.size.f3};
  font-weight: ${({ t }) => t.weight.f7};
  text-transform: uppercase;
  font-family: "MTNBrighterSans-Bold";
  :active {
    box-shadow: none;
  }
`;

const StyledOutlinedButton = styled.button`
  color: ${({ t, secondary }) =>
    secondary ? t.color.light.fg[0] : t.color.accent.fg[1]};
  background: ${({ t, secondary, loading, disabled }) =>
    loading || disabled
      ? t.color.middle_grey.bg
      : secondary
      ? t.color.light.bg
      : t.color.primary.fg[0]};
  border: 2px solid
    ${({ t, secondary, loading, disabled }) =>
      loading || disabled
        ? t.color.middle_grey.bg
        : secondary && t.color.primary.bg};
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 24px;
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ t }) => t.size.f3};
  text-transform: uppercase;
  :active {
    box-shadow: none;
  }
`;

const StyledBackButton = styled.button`
  color: ${({ t }) => t.color.accent.fg[1]};
  background: ${({ t }) => t.color.accent.bg};
  border: 0px solid ${({ t }) => t.color.primary.bg};
  :active {
    box-shadow: none;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
`;

const StyledTextButton = styled.div`
  color: ${({ t }) => t.color.accent.fg[1]};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  width: 100%;
  display: flex;
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  background-color: none;
  font-size: ${({ t }) => t.size.f3};
  font-weight: ${({ t }) => t.weight.f7};
  text-transform: uppercase;
  :active {
    color: ${({ t }) => t.color.accent.fg[3]}
`;

export const StyledImgButton = styled.img`
  width: 10px;
  height: 10px;
  animation: ${({ spin }) => (spin ? "rotation 2s linear infinite" : "none")};
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

StyledImgButton.defaultProps = {
  src: ICONS.RefreshIcon,
};

export const Button = ({
  title,
  loading,
  disabled = false,
  style,
  action = () => {},
  secondary,
  icon,
  noBorder,
}) => {
  const { theme: t } = useContext(ThemeContext);

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      action();
    },
    [action]
  );

  return (
    <StyledPrimaryButton
      onClick={handleClick}
      t={t}
      secondary={secondary}
      loading={loading ? 1 : 0}
      disabled={disabled}
      noBorder={noBorder}
      style={style}
    >
      {icon && (
        <>
          <img src={icon} alt={""} />
          <s.SpacerMedium t={t} />
        </>
      )}
      {loading ? <s.StyledLoader /> : title}
    </StyledPrimaryButton>
  );
};

export const BackButton = ({ style, action = () => {} }) => {
  const { theme: t } = useContext(ThemeContext);

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      action();
    },
    [action]
  );

  return (
    <StyledBackButton onClick={handleClick} t={t} style={style}>
      <img
        src={ICONS.BackIcon}
        alt={"back-icon"}
        style={{ fontSize: t.size.f6 }}
      />
    </StyledBackButton>
  );
};

export const OutlinedButton = ({
  title,
  loading,
  disabled,
  style,
  action = () => {},
  secondary,
  startIcon,
}) => {
  const { theme: t } = useContext(ThemeContext);

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      action();
    },
    [action]
  );

  return (
    <StyledOutlinedButton
      onClick={handleClick}
      t={t}
      secondary={secondary}
      loading={loading ? 1 : 0}
      disabled={disabled}
      style={style}
      className={"mtn-bold"}
    >
      {startIcon && !loading ? (
        <>
          <img
            src={startIcon}
            alt={"MyMtn Logo"}
            loading={"lazy"}
            height={"15px"}
            width={"15px"}
          />
          <s.SpacerXXSmall t={t} />
        </>
      ) : null}
      {loading ? <s.StyledLoader /> : title}
    </StyledOutlinedButton>
  );
};

export const TextButton = ({
  title,
  loading,
  disabled,
  style,
  jc,
  ai,
  action = () => {},
}) => {
  const { theme: t } = useContext(ThemeContext);

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      action();
    },
    [action]
  );

  return (
    <StyledTextButton
      onClick={handleClick}
      t={t}
      loading={loading ? 1 : 0}
      disabled={disabled}
      style={style}
      jc={jc}
      ai={ai}
    >
      {loading ? <s.StyledLoader /> : title}
    </StyledTextButton>
  );
};

export const RefreshButton = ({ spin, onClick }) => {
  return <StyledImgButton alt={"refresh-btn"} spin={spin} onClick={onClick} />;
};

export const CustomFab = ({ icon, action }) => {
  return (
    <Fab
      size={"medium"}
      style={{
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0.12) 50%, rgba(0, 0, 0, 0.12) 150%), #FFFFFF",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.24)",
      }}
      aria-label="add"
      onClick={action}
    >
      <img src={icon} width={10} height={24} alt={"kebab"} />
    </Fab>
  );
};
