/* istanbul ignore file */
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { auth0Config } from "../../utils/auth0Config";

const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = useCallback(
    (_appState) => {
      //appState?.returnTo ||
      history.push(window.location.pathname);
    },
    [history]
  );

  return (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      redirectUri={auth0Config.redirectUri}
      onRedirectCallback={onRedirectCallback}
      audience={auth0Config.audience}
      useRefreshTokens={auth0Config.useRefreshTokens}
      cacheLocation={auth0Config.cacheLocation}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
