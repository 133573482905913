/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: false,
  showRewardFlag: false,
  incentive_loading: false,
  incentive_data: {},
  error_msg: "",
  success: false,
};

const rewardSlice = createSlice({
  name: REDUCER.REWARD,
  initialState,
  reducers: {
    REQUEST(state) {
      state.loading = true;
    },
    SUCCESS(state, action) {
      state.loading = false;
      state.showRewardFlag = action.payload.showRewardFlag;
      state.error_msg = "";
      state.success = action.payload.success;
    },
    FAILED(state, action) {
      state.loading = false;
      state.showRewardFlag = false;
      state.error_msg = action.payload.message;
      state.success = false;
    },
    DISMISS_MODAL(state, action) {
      state.showRewardFlag = false;
    },
    INCENTIVE_REQUEST(state) {
      state.incentive_loading = true;
    },
    INCENTIVE_SUCCESS(state, action) {
      state.incentive_loading = false;
      state.incentive_data = action.payload;
    },
    CLEAR(state) {
      state.loading = false;
      state.showRewardFlag = false;
      state.error_msg = "";
      state.success = false;
    },
  },
});

export const rewardAction = rewardSlice.actions;

export default rewardSlice;
