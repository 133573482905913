/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: false,
  isError: false,
  flexi_catalog: {},
  combo: [],
  errorMessage: "",
};

const flexiSlice = createSlice({
  name: REDUCER.FETCH_FLEXI,
  initialState,
  reducers: {
    REQUEST(state) {
      state.loading = true;
    },
    FLEXI_SUCCESS(state, action) {
      state.loading = false;
      state.isError = false;
      state.flexi_catalog = action.payload;
      state.errorMessage = "";
    },
    FLEXI_FAILED(state, action) {
      state.loading = false;
      state.isError = true;
      state.flexi_catalog = {};
      state.errorMessage = action.payload.message;
    },
    FLEXI_CLEAR(state) {
      state.loading = false;
      state.isError = false;
      state.errorMessage = "";
      state.flexi_catalog = {};
    },
    COMBO_SUCCESS(state, action) {
      state.loading = false;
      state.isError = false;
      state.combo = action.payload;
      state.errorMessage = "";
    },
    COMBO_FAILED(state, action) {
      state.loading = false;
      state.isError = true;
      state.combo = {};
      state.errorMessage = action.payload.message;
    },
    COMBO_CLEAR(state) {
      state.loading = false;
      state.isError = false;
      state.errorMessage = "";
      state.combo = {};
    },
  },
});

export const flexiActions = flexiSlice.actions;
export default flexiSlice;
