/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: false,
  isError: false,
  payload: {},
  isSubscribedToOffer: false,
  showHolidayOffer: false,
  holidayData: {},
  count: 5,
};

const holidaySlice = createSlice({
  name: REDUCER.JUST4U,
  initialState,
  reducers: {
    REQUEST(state) {
      state.loading = true;
    },
    SUCCESS(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.count = 0;
      state.showHolidayOffer = false;
    },
    FAILURE(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.isError = true;
    },
    SHOW_OFFER(state, action) {
      state.showHolidayOffer = action.payload;
    },
    SUBSCRIPTION_STATUS(state, action) {
      state.isSubscribedToOffer = action.payload;
    },
    HOLIDAY_DATA(state, action) {
      state.holidayData = action.payload;
      state.showHolidayOffer = true;
      if (state.count !== 0) {
        state.count = state.count - 1;
      } else {
        state.showHolidayOffer = false;
      }
    },
    CLEAR(state) {
      state.loading = false;
      state.payload = {};
      state.isError = false;
      state.isSubscribedToOffer = false;
      state.showHolidayOffer = true;
      state.holidayData = {};
      state.count = 5;
    },
  },
});

export const holidayActions = holidaySlice.actions;

export default holidaySlice;
