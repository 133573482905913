/* istanbul ignore file */
import { SETUP_CONSTANT } from "../../../config/endPointsConfig";
import {
  API_CONST,
  APP_ROUTES,
  FETCH_OPTIONS,
} from "../../../constants/appConstants";
import { flexiActions } from "./flexiSlice";

export const fetchFlexiByCategory = (cat, getAccessTokenSilently) => {
  const url = `${SETUP_CONSTANT.endpoints.shop}fetchFlexiBundle/${cat}?country=gha`;

  return async (dispatch) => {
    dispatch(flexiActions.REQUEST());

    const token = await getAccessTokenSilently();

    if (token == null || token === "") {
      return;
    }

    const headers = {
      ...API_CONST.AUTHORIZATION(token),
      ...API_CONST.X_COUNTRY_CODE,
    };

    try {
      const response = await fetch(url, FETCH_OPTIONS.getData(headers));
      response
        .json()
        .then((data) => {
          if (response.ok) {
            dispatch(flexiActions.FLEXI_SUCCESS(data));
          } else {
            dispatch(flexiActions.FLEXI_FAILED(data));
          }
        })
        .catch((e) => {
          dispatch(flexiActions.FLEXI_FAILED({ message: e.message }));
        });
    } catch (error) {
      dispatch(flexiActions.FLEXI_FAILED({ message: error.message }));
    }
  };
};

export const fetchFlexiComboByAmount = (
  amount,
  history,
  getAccessTokenSilently
) => {
  const url = `${SETUP_CONSTANT.endpoints.shop}fetchPulseFlexi?country=gha`;

  return async (dispatch) => {
    dispatch(flexiActions.REQUEST());

    const token = await getAccessTokenSilently();

    if (token == null || token === "") {
      return;
    }

    const headers = {
      ...API_CONST.AUTHORIZATION(token),
      ...API_CONST.X_COUNTRY_CODE,
    };

    try {
      const response = await fetch(
        url,
        FETCH_OPTIONS.postData({ amount: parseFloat(amount) }, headers)
      );
      response
        .json()
        .then((data) => {
          if (response.status === 200) {
            dispatch(flexiActions.COMBO_SUCCESS(data));
            history.push(APP_ROUTES.SELECTED_BUNDLE_LIST, {
              bundles: {
                name: "Mashup bundles",
                bundles: data,
                isMashup: true,
              },
            });
          } else if (response.status === 400) {
            dispatch(flexiActions.COMBO_FAILED(data));
          } else {
            dispatch(
              flexiActions.COMBO_FAILED({ message: "Please try again!" })
            );
          }
        })
        .catch((e) => {
          dispatch(flexiActions.COMBO_FAILED({ message: e.message }));
        });
    } catch (error) {
      dispatch(flexiActions.FLEXI_FAILED({ message: error.message }));
    }
  };
};

export const clearFlexi = () => {
  return (dispatch) => {
    dispatch(flexiActions.COMBO_CLEAR());
  };
};
