/* istanbul ignore file */

import { SETUP_CONSTANT } from "../config/endPointsConfig";

export const auth0Config = {

  domain: "auth.mtn.com.gh",
  clientId: "gPGMcIZKWU6JwhmmFEgotji40OD7DKZY",
  redirectUri: `${SETUP_CONSTANT.endpoints.auth0RedirectUrl}`,
  audience: "NextGenAPI",
  useRefreshTokens: "true",
  cacheLocation: "localstorage"

};
