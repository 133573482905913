/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";
import { User } from "../../models/User";

const initialState = {
  loading: false,
  errorMsg: null,
  user: new User(),
  isRedirect: false,
};

const authSlice = createSlice({
  name: REDUCER.AUTH,
  initialState,
  reducers: {
    AUTH_REQUEST(state) {
      state.loading = true;
    },
    AUTH_SUCCESS(state, action) {
      state.loading = false;
      state.errorMsg = null;
      state.user = { ...state.user, ...action.payload };
    },
    AUTH_FAILED(state, action) {
      state.loading = false;
      state.errorMsg = action.payload;
    },
    SET_PHOTO_URL(state, action) {
      state.user = { ...state.user, ...action.payload };
    },
    REMOVE_PHOTO_URL(state) {
      state.user = { ...state.user, photo_url: "" };
    },
    CLEAR_AUTH(state) {
      state.isRedirect = false;
      state.loading = false;
      state.errorMsg = null;
      state.user = new User();
    },
    SET_ISREDIRECT(state, action) {
      state.isRedirect = action.payload;
      state.loading = false;
      state.errorMsg = null;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice;
