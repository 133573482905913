/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  shouldShowNotification: true,
  hasShownAlready: false,
};

const notificationSlice = createSlice({
  name: REDUCER.NOTIFICATION,
  initialState,
  reducers: {
    TOGGLE(state, action) {
      state.shouldShowNotification = action.payload.shouldShowNotification;
      state.hasShownAlready = action.payload.hasShownAlready;
    },
    RESET_NOTIFICATION(state, action) {
      state.shouldShowNotification = true;
      state.hasShownAlready = false;
    },
  },
});

export const notificationActions = notificationSlice.actions;
export default notificationSlice;
