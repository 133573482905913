import { SvgIcon } from "@material-ui/core";

const HomeIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
};

const ShopIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
    </SvgIcon>
  );
};

const MomoIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 14.0542C16.59 9.3292 15.75 3.5542 15.75 3.5542L21 8.8042C21 8.8042 18.9 10.9042 15.75 14.0542Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7 18.2541C15.7813 6.8894 5.40631 1.4541 5.40631 1.4541L0 9.85411C0 9.85411 9.83433 11.8306 14.7 18.2541ZM6.30001 9.85384C7.45981 9.85384 8.40001 8.91364 8.40001 7.75384C8.40001 6.59404 7.45981 5.65384 6.30001 5.65384C5.14021 5.65384 4.20001 6.59404 4.20001 7.75384C4.20001 8.91364 5.14021 9.85384 6.30001 9.85384Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.87982 0C9.87982 0 14.6525 4.77273 14.6525 10.5C12.6003 4.60385 8.448 2.38637 8.448 2.38637L9.87982 0Z"
      />
    </SvgIcon>
  );
};

const MoreIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
    </SvgIcon>
  );
};

export { HomeIcon, ShopIcon, MomoIcon, MoreIcon };
