// Constants
import THEME from "../constants/themeConstants";
// Themes
import generic from "./themes/generic";

const themes = {
  generic: generic,
};

const Theme = (() => {
  let currentTheme = themes[THEME.GENERIC];

  return {
    set: (theme) => {
      if (!theme) {
        return currentTheme;
      }
      currentTheme = themes[theme];
      return currentTheme;
    },
  };
})();

export default Theme;
