/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  count: 0,
};

const authErrorSlice = createSlice({
  name: REDUCER.AUTH_ERROR,
  initialState,
  reducers: {
    INCREMENT_SUCCESS(state) {
      state.count = state.count + 1;
    },
    RESET_SUCCESS(state) {
      state.count = 0;
    },
  },
});

export const authErrorActions = authErrorSlice.actions;
export default authErrorSlice;
