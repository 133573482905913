/* istanbul ignore file */
import { agentTrackingActions } from "./agentTrackingSlice";
import {
  API_CONST,
  APP_ROUTES,
  FETCH_OPTIONS,
  GENERAL,
} from "../../../constants/appConstants";
import {
  agentCodeSuccess,
  agentCodeFailure,
  appsFlyerAgentCodeSuccess,
  appsFlyerAgentCodeFailure,
} from "../../../utils/trackerFunctions";
import { SETUP_CONSTANT } from "../../../config/endPointsConfig";

export const setAgent = (agent_code, history, getAccessTokenSilently) => {
  return async (dispatch) => {
    const token = await getAccessTokenSilently();

    if (token == null || token === "") {
      return;
    }

    dispatch(agentTrackingActions.REQUEST());
    const url = `${SETUP_CONSTANT.endpoints.agentTracking}trackAgent`;
    const headers = {
      ...API_CONST.X_COUNTRY_CODE,
      ...API_CONST.AUTHORIZATION(token),
      appCode: GENERAL.APP_CODE,
    };

    const response = await fetch(
      url,
      FETCH_OPTIONS.postData(
        {
          agentCode: agent_code,
          appCode: GENERAL.APP_CODE,
          imei: "",
          phoneOsVersion: "",
          imsi: "",
          sourceChannel: "",
          deviceLocation: "",
        },
        headers
      )
    );
    response
      .json()
      .then((data) => {
        if (data?.success === "true" || data?.success === true) {
          dispatch(agentTrackingActions.SUCCESS(data));
          appsFlyerAgentCodeSuccess();

          //Analytics
          agentCodeSuccess(token);

          history.push(APP_ROUTES.REVIEW_PAY_RESPONSE, {
            responseDetails: {
              isError: 1,
              title: "Success",
              description: data?.message,
              isOtherSuccess: true,
            },
          });
        } else {
          dispatch(agentTrackingActions.FAILED(data));
          appsFlyerAgentCodeFailure();

          //Analytics
          agentCodeFailure(token);

          history.push(APP_ROUTES.REVIEW_PAY_RESPONSE, {
            responseDetails: {
              isError: 0,
              title: "Error",
              description: data?.message,
              isOtherSuccess: true, //used to hide upgrade notification from showing on agent tracking success
            },
          });
        }
      })
      .catch((e) => {
        dispatch(
          agentTrackingActions.FAILED({
            error: "Something unexpected happend. Kindly try again",
          })
        );
        appsFlyerAgentCodeFailure();

        //Analytics
        agentCodeFailure(token);

        history.push(APP_ROUTES.REVIEW_PAY_RESPONSE, {
          responseDetails: {
            isError: 0,
            title: "Error",
            description: e.message,
          },
        });
      });
  };
};

export const setAgentTimeout = (history) => {
  return (dispatch) => {
    dispatch(agentTrackingActions.TIMEOUT());
    history.goBack();
  };
};

export const startAgentTimer = (getAccessTokenSilently) => {
  return async (dispatch) => {
    const token = await getAccessTokenSilently();

    if (token == null || token === "") {
      return;
    }

    dispatch(agentTrackingActions.START_TIMER());
    const url = `${SETUP_CONSTANT.endpoints.agentTracking}agentValidation`;
    const headers = {
      ...API_CONST.X_COUNTRY_CODE,
      ...API_CONST.AUTHORIZATION(token),
      appCode: GENERAL.APP_CODE,
    };

    const getResponse = await fetch(url, FETCH_OPTIONS.getData(headers));
    getResponse
      .json()
      .then(async (data) => {
        if (getResponse.status === 200) {
          if (!data) {
            const postResponse = await fetch(
              url,
              FETCH_OPTIONS.postData({}, headers)
            );
            postResponse
              .json()
              .then((postData) => {
                if (postResponse.status === 400) {
                  dispatch(agentTrackingActions.START_TIMER_FAILURE(postData));
                } else {
                  dispatch(agentTrackingActions.START_TIMER_SUCCESS(postData));
                }
              })
              .catch((e) => {
                dispatch(
                  agentTrackingActions.START_TIMER_FAILURE({ error: e.message })
                );
              });
          } else {
            dispatch(agentTrackingActions.START_TIMER_SUCCESS(data));
          }
        }
      })
      .catch((ex) => {
        dispatch(
          agentTrackingActions.START_TIMER_FAILURE({ error: ex.message })
        );
      });
  };
};

export const clearAgentTracking = () => {
  return (dispatch) => {
    dispatch(agentTrackingActions.CLEAR());
  };
};
