export class UserNumber {
  constructor({
    msisdn = "",
    subscription_type = "",
    status = "",
    balances = null,
  } = {}) {
    this.msisdn = msisdn;
    this.subscription_type = subscription_type;
    this.refresh_statustoken = status;
    this.balances = balances;
  }
}
