/* istanbul ignore file */
import { configActions } from "./configSlice";

export const appsFlyerInitConfig = (userId) => {
  window.AF("pba", "setCustomerUserId", userId);
};

export const clearConfig = () => {
  return (dispatch) => {
    dispatch(configActions.CLEAR_CONFIG());
  };
};
