/* istanbul ignore file */
import { authErrorActions } from "./authErrorSlice";

export const incrementErrorCount = () => (dispatch) => {
  dispatch(authErrorActions.INCREMENT_SUCCESS());
};

export const resetErrorCount = () => (dispatch) => {
  dispatch(authErrorActions.RESET_SUCCESS());
};
