/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: true,
  get: null,
  api: null,
  tracker: null,
  errorMsg: null,
};

const configSlice = createSlice({
  name: REDUCER.CONFIG,
  initialState,
  reducers: {
    CONFIG_REQUEST(state) {
      state.loading = true;
    },
    CONFIG_SUCCESS(state, action) {
      state.loading = false;
      state.get = action.payload.get;
      state.api = action.payload.api;
      state.tracker = action.payload.analytics;
      state.errorMsg = null;
    },
    CONFIG_FAILED(state, action) {
      state.loading = false;
      state.errorMsg = action.payload;
    },
    CLEAR_CONFIG(state) {
      state.loading = false;
      state.get = null;
      state.api = null;
      state.tracker = null;
      state.errorMsg = null;
    },
  },
});

export const configActions = configSlice.actions;

export default configSlice;
