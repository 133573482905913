/* istanbul ignore file */
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import { REDUCER } from "../../constants/appConstants";

import authSlice from "./auth/authSlice";
import loginSlice from "./login/loginSlice";

import { persistStore, persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";
import helpSlice from "./help/helpSlice";
import platformSlice from "./platform/platformSlice";
import customerBalancesSlice from "./customerBalances/customerBalancesSlice";
import modalSlice from "./modal/modalSlice";
import agentTrackingSlice from "./agentTracking/agentTrackingSlice";
import productCatalogSlice from "./productCatalog/productCatalogSlice";
import notificationSlice from "./notification/notificationSlice";
import upgradeSlice from "./notification/upgradeNotificationSlice";
import subscriptionSlice from "./subscriptions/subscriptionsSlice";
import configSlice from "./config/configSlice";

import serviceWorkerSlice from "./serviceWorker/serviceWorkerSlice";
import rewardSlice from "./reward/rewardSlice";
import recentContactsSlice from "./recentContacts/recentContactsSlice";
import flexiSlice from "./flexi/flexiSlice";
import validateMsisdnSlice from "./validateMsisdn/validateMsisdnSlice";
import just4uSlice from "./just4u/just4uSlice";
import authErrorSlice from "./auth0Error/authErrorSlice";
import broadbandSlice from "./broadband/broadbandSlice";
import holidaySlice from "./holiday/holidaySlice";
import { usageReducer } from "./usage/usageSlice";

const rootReducer = combineReducers({
  [REDUCER.CONFIG]: configSlice.reducer,
  [REDUCER.AUTH]: authSlice.reducer,
  [REDUCER.LOGIN]: loginSlice.reducer,
  [REDUCER.HELP]: helpSlice.reducer,
  [REDUCER.APP_PLATFORM]: platformSlice.reducer,
  [REDUCER.CUSTOMERBALANCES]: customerBalancesSlice.reducer,
  [REDUCER.MODAL]: modalSlice.reducer,
  [REDUCER.AGENT_TRACKING]: agentTrackingSlice.reducer,
  [REDUCER.PRODUCT_CATALOG]: productCatalogSlice.reducer,
  [REDUCER.NOTIFICATION]: notificationSlice.reducer,
  [REDUCER.UPGRADE_NOTIFICATION]: upgradeSlice.reducer,
  [REDUCER.SUBSCRIPTIONS]: subscriptionSlice.reducer,
  [REDUCER.SERVICE_WORKER]: serviceWorkerSlice.reducer,
  [REDUCER.REWARD]: rewardSlice.reducer,
  [REDUCER.CONTACTS]: recentContactsSlice.reducer,
  [REDUCER.FETCH_FLEXI]: flexiSlice.reducer,
  [REDUCER.VALIDATE_MSISDN]: validateMsisdnSlice.reducer,
  [REDUCER.JUST4U]: just4uSlice.reducer,
  [REDUCER.AUTH_ERROR]: authErrorSlice.reducer,
  [REDUCER.BROADBAND]: broadbandSlice.reducer,
  [REDUCER.HOLIDAY]: holidaySlice.reducer,
  [REDUCER.USAGE]: usageReducer,
});

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    [REDUCER.MODAL],
    // [REDUCER.AGENT_TRACKING],
    [REDUCER.CONTACTS],
    [REDUCER.CONFIG],
    [REDUCER.VALIDATE_MSISDN],
  ],
  whitelist: [
    REDUCER.AUTH_ERROR,
    REDUCER.AUTH,
    REDUCER.LOGIN,
    REDUCER.NOTIFICATION,
    REDUCER.UPGRADE_NOTIFICATION,
    REDUCER.CUSTOMERBALANCES,
    REDUCER.HELP,
    REDUCER.CONFIG,
    REDUCER.FETCH_FLEXI,
    REDUCER.SUBSCRIPTIONS,
    REDUCER.PRODUCT_CATALOG,
    REDUCER.JUST4U,
    REDUCER.REWARD,
    REDUCER.BROADBAND,
    REDUCER.HOLIDAY,
    REDUCER.CONTACTS,
    REDUCER.AGENT_TRACKING,
    REDUCER.USAGE,
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);

export default store;
