/* istanbul ignore file */
import { SETUP_CONSTANT } from "../../../config/endPointsConfig";
import { rewardAction } from "./rewardSlice";

export const checkReward = (getAccessTokenSilently) => {
  return async (dispatch) => {
    dispatch(rewardAction.REQUEST());
    const url = `${SETUP_CONSTANT.endpoints.rewardBaseUrl}api/reward`;

    const token = await getAccessTokenSilently();

    if (token == null || token === "") {
      return;
    }

    const params = {
      method: "POST",
      cache: "default",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Accept-Encoding": "gzip,deflate,br",
        "X-Country-Code": "gha",
        platform: "pwa",
        api_version: "v2",
        Authorization: `Bearer ${token}`,
      },
      body: null,
    };

    try {
      const response = await fetch(url, params);
      response
        .json()
        .then(async (res) => {
          if (response.status === 200) {
            if (res.success) {
              dispatch(rewardAction.SUCCESS(res));
            }
          } else {
            dispatch(rewardAction.FAILED(res?.message));
          }
        })
        .catch((e) => {
          dispatch(rewardAction.FAILED({ message: e?.message }));
        });
    } catch (e) {
      dispatch(rewardAction.FAILED({ message: e?.message }));
    }
  };
};

export const checkIncentiveEligibility = (getAccessTokenSilently) => {
  return async (dispatch) => {
    const url = `${SETUP_CONSTANT.endpoints.rewardBaseUrl}api/checkEligibility`;
    const token = await getAccessTokenSilently();

    if (token == null || token === "") {
      return;
    }
    dispatch(rewardAction.INCENTIVE_REQUEST());
    const params = {
      method: "POST",
      cache: "default",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Accept-Encoding": "gzip,deflate,br",
        "X-Country-Code": "gha",
        Authorization: `Bearer ${token}`,
      },
      body: null,
    };

    try {
      const response = await fetch(url, params);
      response
        .json()
        .then(async (res) => {
          if (res) {
            dispatch(rewardAction.INCENTIVE_SUCCESS(res));
          }
        })
        .catch(() => dispatch(rewardAction.INCENTIVE_SUCCESS()));
    } catch {
      dispatch(rewardAction.INCENTIVE_SUCCESS());
    }
  };
};

export const dismissRewardModal = () => {
  return (dispatch) => {
    dispatch(rewardAction.DISMISS_MODAL());
  };
};

export const clearReward = () => {
  return (dispatch) => {
    dispatch(rewardAction.CLEAR());
  };
};
