/* istanbul ignore file */
import React, { useEffect, Suspense, lazy, useCallback } from "react";
import { ThemeProvider } from "./context/themeContext";
import { persistor } from "./persistance/redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPlatform } from "./persistance/redux";
import { PersistGate } from "redux-persist/integration/react";
import ErrorBoundary from "./pages/ErrorBoundary";
import { useAuth0 } from "@auth0/auth0-react";
import FallbackLoader from "./shared/components/FallbackLoader";
import { appsFlyerInitConfig } from "./persistance/redux/config/configActions";
import UpdateBottomSheet from "./shared/components/UpdateBottomSheet";
import { setSessionId, getSessionId } from "./utils/sessionUtils";
const AppRoutes = lazy(() => import("./AppRoutes"));

export let deferredInstall = null;
export let isPWAInstalled = false;
export let user_msisdn = "";

if ("serviceWorker" in navigator) {
  window.addEventListener("beforeinstallprompt", (ev) => {
    if (ev.origin === null) return;
    ev.preventDefault();
    deferredInstall = ev;
  });

  window.addEventListener("appinstalled", () => {
    isPWAInstalled = true;
    deferredInstall = null;
  });
}

export function installPWA() {
  if (deferredInstall) {
    deferredInstall.prompt();

    deferredInstall.userChoice.then((choice) => {
      if (choice.outcome === "accepted") {
        isPWAInstalled = true;
      }
    });
  }
}

function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { serviceWorkerRegistration, serviceWorkerUpdated } = useSelector(
    (state) => state.service_worker
  );
  const { isLoading } = useAuth0();

  useEffect(() => {
    if (!getSessionId()) {
      setSessionId();
    }
  }, [serviceWorkerUpdated, serviceWorkerRegistration]);

  useEffect(() => {
    if (auth?.user?.user_id !== "") {
      appsFlyerInitConfig(auth?.user?.user_id);
    }
  }, [dispatch, auth]);

  useEffect(() => {
    if (auth?.user?.primary_number !== "") {
      let _msisdn = auth?.user?.primary_number?.msisdn;
      const __msisdn = _msisdn.replace(/[^0-9 ]/g, "");
      user_msisdn = __msisdn;
    }
  }, [dispatch, auth?.user?.primary_number]);

  useEffect(() => {
    dispatch(getPlatform());
  }, [dispatch]);

  const updateServiceWorker = useCallback(() => {
    const registrationWaiting = serviceWorkerRegistration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });

      registrationWaiting.addEventListener("statechange", (e) => {
        if (e.target.state === "activated") {
          window.location.reload();
        }
      });
    } else {
      window.location.reload();
    }
  }, [serviceWorkerRegistration]);

  if (isLoading) {
    return <FallbackLoader />;
  }

  return (
    <Suspense fallback={<FallbackLoader />}>
      <PersistGate persistor={persistor}>
        <ThemeProvider>
          <ErrorBoundary>
            <Router>
              <AppRoutes />
            </Router>
          </ErrorBoundary>
          <UpdateBottomSheet
            serviceWorkerUpdated={serviceWorkerUpdated}
            onUpdateClicked={updateServiceWorker}
          />
        </ThemeProvider>
      </PersistGate>
    </Suspense>
  );
}

export default App;
