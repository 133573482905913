/* istanbul ignore file */
import { user_msisdn } from "../App";
import { SETUP_CONSTANT } from "../config/endPointsConfig";
import { API_CONST, FETCH_OPTIONS } from "../constants/appConstants";
import {
  getBrowserName,
  getBrowserVersion,
  getEngine,
  getOperatingSystem,
} from "./browserVersionUtil";

import microseconds from "microseconds";
import AnalyticsConfig from "./analyticsConfig";
import { getSessionId } from "./sessionUtils";

const trackEvents = async (event_params, getAccessTokenSilently) => {
  try {
    const url = SETUP_CONSTANT.endpoints.analytics;

    const defaultObject = {
      project_id: AnalyticsConfig.id,
      environment: AnalyticsConfig.environment,
      event: {
        event_id: microseconds.now(),
        event_timestamp: microseconds.now(),
        event_date: null,
        user_id: user_msisdn,
        ciam_id: null,
        device_token: "",
        email_id: "",
        cart_id: "",
        transaction_id: `${microseconds.now()}`,
        user_country: "GHA",
        client_event_timestamp: microseconds.now(),
        client_event_date: null,
        session: {
          id: getSessionId(),
          source: null,
          start_time: microseconds.now(),
          utm: [
            {
              key: "utm_source",
              value: null,
            },
            {
              key: "utm_medium",
              value: null,
            },
            {
              key: "utm_campaign",
              value: null,
            },
            {
              key: "utm_term",
              value: null,
            },
            {
              key: "utm_content",
              value: null,
            },
          ],
        },
        user_properties: [],
        account_type: "",
        account_balances: [],
        app_info: {
          id: "com.mymtnnextgen.lite",
          version: "1.0.0",
          install_referrer: "",
          first_installation_time: 0,
          last_update_time: 0,
          installation_id: "",
        },
        app_performance: {
          battery_percentage: null,
          battery_low_power_mode: null,
          battery_is_charging: null,
          data_down_kb: null,
          data_up_kb: null,
          battery_seconds_to_charged: null,
          battery_seconds_to_discharged: null,
        },
        device: {
          brand: "",
          device_type: "",
          browser_version: getBrowserName(),
          engine: getEngine(),
          engine_version: getBrowserVersion(),
          model: "",
          operating_system: getOperatingSystem(),
          operating_system_version: "",
          supported_architectures: "",
          timezone_offset_sec: 0,
          total_memory: 0,
          storage_free: 0.0,
          storage_total: 0.0,
          screen_height: window.innerHeight,
          screen_width: window.innerWidth,
          unique_device_id: "",
          used_memory: 0,
          pin_or_fingerprint_set: false,
        },
        network: {
          carrier: "MTN Ghana",
          carrier_iso_country_code: null,
          cellular_generation: navigator?.connection?.effectiveType,
          mobile_country_code: null,
          mobile_network_code: null,
          network_state_type: "",
          save_data_mode: null,
        },
        channel: "web",
        ...event_params,
      },
    };

    const _token = await getAccessTokenSilently();

    const payload = { ...defaultObject };
    const headers = {
      ...API_CONST.X_COUNTRY_CODE,
      ...API_CONST.AUTHORIZATION(_token),
    };

    const response = await fetch(url, FETCH_OPTIONS.postData(payload, headers));
    response
      .json()
      .then((d) => {
        //console.log("success => ", d)
      })
      .catch((e) => {
        //console.log("Analytics event params : => ", event_params);
        // console.log("Analytics error : => ", e);
      });
  } catch ({ message }) {}
};

const reviewAndPayLanding = (token) => {
  const _payload = {
    event_name: "view_screen",
    event_params: [
      {
        key: "feature",
        value: { string: "shop" },
      },
      {
        key: "screen",
        value: { string: "review" },
      },
    ],
  };

  trackEvents(_payload, token);
};

const enterMsisdnScreenLanding = (token) => {
  const _payload = {
    event_name: "view_screen",
    event_params: [
      {
        key: "feature",
        value: { string: "auth" },
      },
      {
        key: "screen",
        value: { string: "enter_phone_number" },
      },
    ],
  };

  trackEvents(_payload, token);
};

const profileSimDetailLanding = (token) => {
  const _payload = {
    event_name: "view_screen",
    event_params: [
      {
        key: "feature",
        value: { string: "profile" },
      },
      {
        key: "screen",
        value: { string: "sim_card_details" },
      },
    ],
  };

  trackEvents(_payload, token);
};

const shopLanding = (token) => {
  const _payload = {
    event_name: "view_screen",
    event_params: [
      {
        key: "feature",
        value: { string: "shop" },
      },
      {
        key: "screen",
        value: { string: "shop" },
      },
    ],
  };

  trackEvents(_payload, token);
};

const appInstall = (token) => {
  const _payload = {
    event_name: "add_to_homescreen",
  };

  trackEvents(_payload, token);
};

const otherVideoLanding = (token) => {
  const _payload = {
    event_name: "view_screen",
    event_params: [
      {
        key: "feature",
        value: { string: "shop" },
      },
      {
        key: "screen",
        value: { string: "other_bundle_video" },
      },
    ],
  };

  trackEvents(_payload, token);
};

const appUpgradeAction = (token) => {
  const _payload = {
    event_name: "app_upgrade",
  };
  trackEvents(_payload, token);
};

const otherMidnightLanding = (token) => {
  const _payload = {
    event_name: "view_screen",
    event_params: [
      {
        key: "feature",
        value: { string: "shop" },
      },
      {
        key: "screen",
        value: { string: "other_bundle_midnight" },
      },
    ],
  };

  trackEvents(_payload, token);
};

const otherKokrokooLanding = (token) => {
  const _payload = {
    event_name: "view_screen",
    event_params: [
      {
        key: "feature",
        value: { string: "shop" },
      },
      {
        key: "screen",
        value: { string: "other_bundle_kokrokoo" },
      },
    ],
  };

  trackEvents(_payload, token);
};

const regularBundleLanding = (token) => {
  const _payload = {
    event_name: "view_screen",
    event_params: [
      {
        key: "feature",
        value: { string: "shop" },
      },
      {
        key: "screen",
        value: { string: "regular_bundle" },
      },
    ],
  };

  trackEvents(_payload, token);
};

const socialBundleLanding = (token) => {
  const _payload = {
    event_name: "view_screen",
    event_params: [
      {
        key: "feature",
        value: { string: "shop" },
      },
      {
        key: "screen",
        value: { string: "social_bundle" },
      },
    ],
  };

  trackEvents(_payload, token);
};

const analitycsresendOtp = (token) => {
  const _payload = {
    event_name: "otp_resend",
  };

  trackEvents(_payload, token);
};

// const otpSentSuccess = (token) => {
//   const _payload = {
//     event_name: "otp_sent",
//     event_params: [
//       {
//         key: "otp_result",
//         value: { string: "success" },
//       },
//     ],
//   };

//   trackEvents(_payload, token);
// };

// const otpSentFail = (token) => {
//   const _payload = {
//     event_name: "otp_sent",
//     event_params: [
//       {
//         key: "otp_result",
//         value: { string: "failed" },
//       },
//     ],
//   };

//   trackEvents(_payload, token);
// };

const otpVerified = (token) => {
  const _payload = {
    event_name: "otp_verified",
  };

  trackEvents(_payload, token);
};

const incorrectOtp = (token) => {
  const _payload = {
    event_name: "app_error",
    event_params: [
      {
        key: "feature",
        value: { string: "error" },
      },
      {
        key: "screen",
        value: { string: "error_screen" },
      },
      {
        key: "error_type",
        value: { string: "otp" },
      },
      {
        key: "error_message",
        value: { string: "incorrect_otp" },
      },
    ],
  };

  trackEvents(_payload, token);
};

const flexiClickLanding = (screen_source, token) => {
  const _payload = {
    event_name: "view_screen",
    event_params: [
      {
        key: "feature",
        value: { string: "shop" },
      },
      {
        key: "screen",
        value: { string: "flexi" },
      },
      {
        key: "screen_source",
        value: { string: screen_source },
      },
    ],
  };

  trackEvents(_payload, token);
};

const insufficientBalance = (token) => {
  const _payload = {
    event_name: "app_error",
    event_params: [
      {
        key: "feature",
        value: { string: "error" },
      },
      {
        key: "screen",
        value: { string: "error_screen" },
      },
      {
        key: "error_type",
        value: { string: "purchase" },
      },
      {
        key: "error_message",
        value: { string: "insufficient balance" },
      },
    ],
  };

  trackEvents(_payload, token);
};

const view_bundle_info = (
  product_type,
  product_name,
  mb,
  product_id,
  token
) => {
  const _payload = {
    event_name: "view_bundle",
    event_params: [
      {
        key: "feature",
        value: { string: "shop" },
      },
      {
        key: "screen",
        value: { string: "bundles" },
      },
      {
        key: "product_type",
        value: { string: product_type },
      },
      {
        key: "product_name",
        value: { string: product_name },
      },
      {
        key: "value",
        value: { integer: mb },
      },
      {
        key: "product_id",
        value: { string: product_id },
      },
    ],
  };

  trackEvents(_payload, token);
};

const agentScreenLanding = (token) => {
  const _payload = {
    event_name: "agent_referral",
    event_params: [
      {
        key: "feature",
        value: { string: "agent_referral" },
      },
      {
        key: "screen",
        value: { string: "agent_screen" },
      },
    ],
  };

  trackEvents(_payload, token);
};

const shopSuccessLanding = (token) => {
  const _payload = {
    event_name: "view_screen",
    event_params: [
      {
        key: "feature",
        value: { string: "shop" },
      },
      {
        key: "screen",
        value: { string: "success" },
      },
    ],
  };

  trackEvents(_payload, token);
};

const profilePrivacyPolicyLanding = (token) => {
  const _payload = {
    event_name: "view_screen",
    event_params: [
      {
        key: "feature",
        value: { string: "legal" },
      },
      {
        key: "screen",
        value: { string: "privacy_policy" },
      },
    ],
  };

  trackEvents(_payload, token);
};

const legalLanding = (token) => {
  const _payload = {
    event_name: "view_screen",
    event_params: [
      {
        key: "feature",
        value: { string: "legal" },
      },
      {
        key: "screen",
        value: { string: "legal" },
      },
    ],
  };

  trackEvents(_payload, token);
};

const helpContactUsLanding = (token) => {
  const _payload = {
    event_name: "view_screen",
    event_params: [
      {
        key: "feature",
        value: { string: "help" },
      },
      {
        key: "screen",
        value: { string: "contact_us" },
      },
    ],
  };

  trackEvents(_payload, token);
};

const moreLanding = (token) => {
  const _payload = {
    event_name: "view_screen",
    event_params: [
      {
        key: "feature",
        value: { string: "profile" },
      },
      {
        key: "screen",
        value: { string: "profile_menu" },
      },
    ],
  };

  trackEvents(_payload, token);
};

const homeScreenLanding = (token) => {
  const _payload = {
    event_name: "view_screen",
    event_params: [
      {
        key: "feature",
        value: {
          string: "dashboard",
        },
      },
      {
        key: "screen",
        value: {
          string: "home",
        },
      },
    ],
  };

  trackEvents(_payload, token);
};

const agentCodeSuccess = (token) => {
  const _payload = {
    event_name: "agent_referral",
    event_params: [
      {
        key: "feature",
        value: { string: "agent_referral" },
      },
      {
        key: "screen",
        value: { string: "agent_screen" },
      },
      {
        key: "code_status",
        value: { string: "success" },
      },
    ],
  };

  trackEvents(_payload, token);
};

const agentCodeFailure = (token) => {
  const _payload = {
    event_name: "agent_referral",
    event_params: [
      {
        key: "feature",
        value: { string: "agent_referral" },
      },
      {
        key: "screen",
        value: { string: "agent_screen" },
      },
      {
        key: "code_status",
        value: { string: "fail" },
      },
    ],
  };

  trackEvents(_payload, token);
};

const shopDataCategoriesLanding = (token) => {
  const _payload = {
    event_name: "view_screen",
    event_params: [
      {
        key: "feature",
        value: { string: "shop" },
      },
      {
        key: "screen",
        value: { string: "data_categories" },
      },
    ],
  };

  trackEvents(_payload, token);
};

const otpScreenLanding = (token) => {
  const _payload = {
    event_name: "view_screen",
    event_params: [
      {
        key: "feature",
        value: { string: "login" },
      },
      {
        key: "screen",
        value: { string: "otp" },
      },
    ],
  };

  trackEvents(_payload, token);
};

const provision_bundle_fail = (token) => {
  const _payload = {
    event_name: "app_error",
    event_params: [
      {
        key: "feature",
        value: { string: "error" },
      },
      {
        key: "screen",
        value: { string: "error_screen" },
      },
      {
        key: "error_type",
        value: { string: "service" },
      },
      {
        key: "error_message",
        value: { string: "bundle provisioning failed" },
      },
    ],
  };

  trackEvents(_payload, token);
};

const bundlePurchase = (
  payment_method,
  flexi_bundle_Bool,
  recipient,
  product_id,
  app_section,
  item_type,
  item_name,
  value,
  price,
  quantity,
  transaction_id,
  token
) => {
  const _payload = {
    event_name: "quick_buy",
    event_params: [
      {
        key: "payment_method",
        value: { string: payment_method },
      },
      {
        key: "flexi_bundle",
        value: { boolean: Boolean(flexi_bundle_Bool) },
      },
      {
        key: "recipient",
        value: { string: recipient },
      },
      {
        key: "app_section",
        value: { string: app_section },
      },
    ],
    cart: [
      {
        id: product_id,
        name: item_name,
        type: item_type,
        validity: null,
        value: value,
        price: price,
        quantity: quantity,
        recipients: [],
      },
    ],
    transaction_id: transaction_id,
  };

  trackEvents(_payload, token);
};

const appsFlyerAgentCodeSuccess = () => {
  window?.AF("pba", "event", {
    eventType: "EVENT",
    eventValue: {
      feature: "agent_referral",
      screen: "agent_screen",
      code_status: "success",
    },
    event_name: "agent_referral",
  });
};

const appsFlyerAgentCodeFailure = () => {
  window?.AF("pba", "event", {
    eventType: "EVENT",
    eventValue: {
      feature: "agent_referral",
      screen: "agent_screen",
      code_status: "fail",
    },
    event_name: "agent_referral",
  });
};

const appsFlyerLoginEvent = () => {
  window?.AF("pba", "event", {
    eventType: "EVENT",
    eventValue: {
      new_user: true,
      feature: "auth",
      screen: "login",
      header_enrichment: true,
    },
    event_name: "login",
  });
};

const appsFlyerVisitedDashboard = () => {
  window?.AF("pba", "event", {
    eventType: "EVENT",
    eventValue: {
      app_open: true,
      feature: "dashboard",
      screen: "home",
      medium: "web",
    },
    event_name: "view_screen",
  });
};

export {
  appInstall,
  otherVideoLanding,
  otherMidnightLanding,
  otherKokrokooLanding,
  regularBundleLanding,
  socialBundleLanding,
  analitycsresendOtp,
  otpVerified,
  incorrectOtp,
  flexiClickLanding,
  view_bundle_info,
  insufficientBalance,
  agentScreenLanding,
  agentCodeSuccess,
  agentCodeFailure,
  otpScreenLanding,
  bundlePurchase,
  shopSuccessLanding,
  provision_bundle_fail,
  // firstUserTrack,
  appUpgradeAction,
  reviewAndPayLanding,
  enterMsisdnScreenLanding,
  shopLanding,
  profileSimDetailLanding,
  profilePrivacyPolicyLanding,
  legalLanding,
  helpContactUsLanding,
  moreLanding,
  homeScreenLanding,
  shopDataCategoriesLanding,
  appsFlyerAgentCodeFailure,
  appsFlyerAgentCodeSuccess,
  appsFlyerLoginEvent,
  appsFlyerVisitedDashboard,
};
