/* istanbul ignore file */
import React from "react";
import * as s from "../../styles/globalStyles";

const FallbackLoader = ({ bgColor, msg }) => {
  return (
    <div
      style={{
        backgroundColor: bgColor ? bgColor : "#FFCB05",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "100%",
        position: "fixed",
        overflow: "hidden",
      }}
    >
      <s.StyledLoader primary />
      <p style={{ color: "#333", fontSize: "12px", marginTop: "20px" }}>
        {msg || "Loading account infomation..."}
      </p>
    </div>
  );
};

export default FallbackLoader;
