/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
};

const serviceWorkerSlice = createSlice({
  name: REDUCER.SERVICE_WORKER,
  initialState,
  reducers: {
    SERVICE_WORKER_INITIALISED(state) {
      state.serviceWorkerInitialized = !state.serviceWorkerInitialized;
    },
    SERVICE_WORKER_UPDATED(state, action) {
      state.serviceWorkerUpdated = !state.serviceWorkerUpdated;
      state.serviceWorkerRegistration = action.payload;
    },
    CLEAR_SERVICE_WORKER(state) {
      state.serviceWorkerInitialized = false;
      state.serviceWorkerUpdated = false;
      state.serviceWorkerRegistration = null;
    },
  },
});

export const serviceWorkerActions = serviceWorkerSlice.actions;

export default serviceWorkerSlice;
