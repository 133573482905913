import { SETUP_CONSTANT } from "../../../config/endPointsConfig";
import { FETCH_OPTIONS, API_CONST } from "../../../constants/appConstants";
import { usageActions } from "./usageSlice";

const usageUrl = SETUP_CONSTANT.endpoints.transaction + "usage";
const defaultTabs = [
  { name: "airtime" },
  { name: "data" },
  { name: "voice" },
  { name: "sms" },
];

export function getBalanceUsage(getAccessTokenSilently, balanceTypes) {
  return async (dispatch) => {
    dispatch(usageActions.REQUEST());

    const token = await getAccessTokenSilently();
    if (!token) return;

    const headers = {
      ...API_CONST.X_COUNTRY_CODE,
      ...API_CONST.AUTHORIZATION(token),
    };

    try {
      await fetch(usageUrl, FETCH_OPTIONS.getData(headers))
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error(response.statusText);
        })
        .then((json) => {
          const payload = getFormattedUsage(json, balanceTypes);
          dispatch(usageActions.SUCCESS(payload));
        });
    } catch (e) {
      dispatch(usageActions.FAIL({ message: e.message }));
    }
  };
}

function getFormattedUsage(payload, balanceTypes) {
  let formattedUsage = [];
  let subscriptions = {};
  const updatedBalanceTypes = [...balanceTypes, { name: "subscription" }];

  updatedBalanceTypes.forEach((type) => {
    const name = type.name === "airtime" ? "recharge" : type.name;
    const structured = payload
      .filter((entry) => entry.bundle_type === name)
      .reduce((group, history) => {
        const { transaction_date } = history;
        const date = transaction_date.split(" ")[0];
        group[date] = group[date] ?? [];
        group[date].push(history);
        return group;
      }, {});

    subscriptions = payload
      .filter((entry) => entry.bundle_type === "subscription")
      .reduce((group, history) => {
        const { transaction_date } = history;
        group[transaction_date] = group[transaction_date] ?? [];
        group[transaction_date].push(history);
        return group;
      }, {});

    formattedUsage.push(structured);
  });
  const updatedStructure = subscriptions
    ? formattedUsage.map((element, idx) =>
        idx === 0 ? { ...element, ...subscriptions } : element
      )
    : formattedUsage;
  return updatedStructure;
}

export const getDateByRange = (
  getAccessTokenSilently,
  range,
  setIsFiltering
) => {
  return async (dispatch) => {
    dispatch(usageActions.REQUEST());

    const token = await getAccessTokenSilently();
    if (!token) return;

    const headers = {
      ...API_CONST.X_COUNTRY_CODE,
      ...API_CONST.AUTHORIZATION(token),
    };

    try {
      await fetch(
        `${usageUrl}?startTime=${range.from}&endTime=${range.to}`,
        FETCH_OPTIONS.getData(headers)
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error(response.statusText);
        })
        .then((json) => {
          const payload = getFormattedUsage(json, defaultTabs);
          dispatch(usageActions.SUCCESS(payload));
          setIsFiltering(false);
        });
    } catch (e) {
      dispatch(usageActions.FAIL({ message: e.message }));
      setIsFiltering(false);
    }
  };
};
