/* istanbul ignore file */
import { customerBalancesActions } from "./customerBalancesSlice";
import { API_CONST, FETCH_OPTIONS } from "../../../constants/appConstants";
import { SETUP_CONSTANT } from "../../../config/endPointsConfig";

export const getCustomerBalances = (fetchDetailed, getAccessTokenSilently) => {
  const url = fetchDetailed
    ? `${SETUP_CONSTANT.endpoints.transaction}customerBalances?detailed=true`
    : `${SETUP_CONSTANT.endpoints.transaction}customerBalances`;

  return async (dispatch) => {
    try {
      const token = await getAccessTokenSilently();

      if (token == null || token === "") {
        return;
      }

      const headers = {
        ...API_CONST.X_COUNTRY_CODE,
        ...API_CONST.AUTHORIZATION(token),
      };

      dispatch(customerBalancesActions.CUSTOMERBALANCES_REQUEST());

      const response = await fetch(url, FETCH_OPTIONS.getData(headers));
      response
        .json()
        .then((data) => {
          if (response.status === 200) {
            if (fetchDetailed) {
              if (data.StatusCode === 200) {
                dispatch(
                  customerBalancesActions.DETAILED_BALANCES(data.balances)
                );
              }
            } else {
              dispatch(customerBalancesActions.CUSTOMERBALANCES_SUCCESS(data));
            }
          } else {
            if (fetchDetailed && data.StatusCode === 200) {
              dispatch(
                customerBalancesActions.DETAILED_BALANCES(data.balances)
              );
            } else {
              dispatch(customerBalancesActions.CUSTOMERBALANCES_SUCCESS(data));
            }
          }
        })
        .catch((e) => {
          dispatch(customerBalancesActions.CUSTOMERBALANCES_FAILED(e));
        });
    } catch (error) {
      dispatch(customerBalancesActions.CUSTOMERBALANCES_FAILED(error));
    }
  };
};

export const getPostpaidBalnces = (fetchDetailed, getAccessTokenSilently) => {
  const url = fetchDetailed
    ? `${SETUP_CONSTANT.endpoints.transaction}customerBalances?postpaid=true&detailed=true`
    : `${SETUP_CONSTANT.endpoints.transaction}customerBalances?postpaid=true`;

  return async (dispatch) => {
    let token = "";
    try {
      const _token = await getAccessTokenSilently();
      token = _token;
    } catch (e) {
      //
    }

    if (token == null || token === "") {
      return;
    }

    dispatch(customerBalancesActions.CUSTOMERBALANCES_REQUEST());

    const headers = {
      ...API_CONST.X_COUNTRY_CODE,
      ...API_CONST.AUTHORIZATION(token),
    };

    const response = await fetch(url, FETCH_OPTIONS.getData(headers));
    response
      .json()
      .then((data) => {
        if (response.status === 200) {
          if (fetchDetailed) {
            if (data.StatusCode === 200) {
              dispatch(
                customerBalancesActions.DETAILED_BALANCES(data.balances)
              );
            }
          } else {
            dispatch(customerBalancesActions.CUSTOMERBALANCES_SUCCESS(data));
          }
        } else {
          dispatch(customerBalancesActions.CUSTOMERBALANCES_FAILED(data));
        }
      })
      .catch((e) => {
        dispatch(customerBalancesActions.CUSTOMERBALANCES_FAILED(e));
      });
  };
};

export const clearCustomerBalances = () => {
  return (dispatch) => {
    dispatch(customerBalancesActions.CUSTOMERBALANCES_CLEAR());
  };
};
