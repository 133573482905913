/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: false,
  payload: [],
  detailedPayload: {},
  errorMsg: "",
};

const customerBalancesSlice = createSlice({
  name: REDUCER.CUSTOMERBALANCES,
  initialState,
  reducers: {
    CUSTOMERBALANCES_REQUEST(state) {
      state.loading = true;
    },
    CUSTOMERBALANCES_SUCCESS(state, action) {
      state.loading = false;
      state.payload = action.payload;
      state.errorMsg = "";
    },
    CUSTOMERBALANCES_FAILED(state, action) {
      state.loading = false;
      state.errorMsg = action.payload;
    },
    CUSTOMERBALANCES_CLEAR(state) {
      state.loading = false;
      state.payload = [];
      state.detailedPayload = {};
      state.errorMsg = "";
    },
    DETAILED_BALANCES(state, action) {
      state.loading = false;
      state.detailedPayload = action.payload;
      state.errorMsg = "";
    },
  },
});

export const customerBalancesActions = customerBalancesSlice.actions;

export default customerBalancesSlice;
