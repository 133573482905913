/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  isOpen: false,
  componentName: null,
  isClose: true,
  install_dismissed: 0,
  upgrade_dismissed: 0,
  max_show_count: 5,
};

const modalSlice = createSlice({
  name: REDUCER.MODAL,
  initialState,
  reducers: {
    MODAL_SAVE(state, action) {
      state.isOpen = action.payload.isOpen;
      state.isClose = action.payload.isClose;
      state.componentName = action.payload.componentName;
      state.install_dismissed = action.payload.install_dismissed + 1;
      state.upgrade_dismissed = action.payload.upgrade_dismissed + 1;
    },
    MODAL_RESET(state, action) {
      state.isOpen = action.payload.isOpen;
      state.isClose = action.payload.isClose;
      state.componentName = action.payload.componentName;
    },
  },
});

export const modalActions = modalSlice.actions;

export default modalSlice;
