/* istanbul ignore file */
import { OS_PLATFORMS } from "../../../constants/appConstants";
import { platformActions } from "./platformSlice";

export const getPlatform = () => {
  return (dispatch) => {
    const platform = getOS();
    dispatch(platformActions.GET_PLATFORM(platform));
  };
};

const getOS = () => {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = OS_PLATFORMS.MAC_OS;
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = OS_PLATFORMS.IOS;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = OS_PLATFORMS.WINDOWS;
  } else if (/Android/.test(userAgent)) {
    os = OS_PLATFORMS.ANDROID;
  } else if (!os && /Linux/.test(platform)) {
    os = OS_PLATFORMS.LINUX;
  }

  return os;
};

export const setPWAInstalledStatus = (status) => {
  return (dispatch) => {
    dispatch(platformActions.PWA_INSTALLED_STATUS(status));
  };
};
