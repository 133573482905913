/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: false,
  isError: false,
  message: "",
  error: "",
  isAgentAssisted: false,
  timer_details: {},
};

const agentTrackingSlice = createSlice({
  name: REDUCER.AGENT_TRACKING,
  initialState,
  reducers: {
    REQUEST(state) {
      state.loading = true;
      state.isError = false;
      state.message = "";
      state.error = "";
      state.isAgentAssisted = false;
    },
    SUCCESS(state, action) {
      state.loading = false;
      state.isError = false;
      state.message = action.payload.message;
      state.error = "";
      state.isAgentAssisted = true;
    },
    FAILED(state, action) {
      state.loading = false;
      state.isError = true;
      state.error = action.payload.error;
      state.isAgentAssisted = false;
    },
    RESET(state) {
      state.loading = false;
      state.isError = false;
      state.message = "";
      state.error = "";
      state.isAgentAssisted = false;
    },
    START_TIMER(state) {
      state.loading = true;
      state.isError = false;
      state.message = "";
      state.error = "";
      state.isAgentAssisted = false;
      state.timer_details = {};
    },
    START_TIMER_SUCCESS(state, action) {
      state.loading = false;
      state.isError = false;
      state.message = "";
      state.error = "";
      state.isAgentAssisted = false;
      state.timer_details = action.payload;
    },
    START_TIMER_FAILURE(state, action) {
      state.loading = false;
      state.isError = true;
      state.message = action.payload.message;
      state.isAgentAssisted = false;
      state.timer_details = {};
    },
    TIMEOUT(state) {
      state.loading = false;
      state.isError = false;
      state.message = "";
      state.error = "";
      state.isAgentAssisted = true;
      state.timer_details = {};
    },
    CLEAR(state) {
      state.loading = false;
      state.isError = false;
      state.message = "";
      state.error = "";
      state.isAgentAssisted = false;
      state.timer_details = {};
    },
  },
});

export const agentTrackingActions = agentTrackingSlice.actions;

export default agentTrackingSlice;
