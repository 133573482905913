/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: false,
  isError: false,
  payload: {},
};

const just4uSlice = createSlice({
  name: REDUCER.JUST4U,
  initialState,
  reducers: {
    REQUEST(state) {
      state.loading = true;
    },
    SUCCESS(state, action) {
      state.loading = false;
      state.payload = action.payload;
    },
    FAILURE(state) {
      state.loading = false;
      state.payload = { categories: [] };
      state.isError = true;
    },
    CLEAR(state) {
      state.loading = false;
      state.payload = {};
      state.isError = false;
    },
  },
});

export const just4uActions = just4uSlice.actions;

export default just4uSlice;
