/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: false,
  digits: null,
  otp: "",
  accessToken: "",
  error: false,
  success: true,
  failedCount: 0,
  errorMsg: "",
};

const loginSlice = createSlice({
  name: REDUCER.LOGIN,
  initialState,
  reducers: {
    LOGIN_REQUEST(state) {
      state.loading = true;
      state.errorMsg = "";
    },
    LOGIN_SUCCESS(state, action) {
      state.loading = false;
      state.digits = action.payload.digits;
      state.otp = action.payload.otp;
      state.success = true;
      state.error = false;
      state.errorMsg = "";
    },
    TOKEN_SUCCESS(state, action) {
      state.accessToken = action.payload;
    },
    LOGIN_FAILED(state, action) {
      state.loading = false;
      state.errorMsg = action.payload;
      state.error = true;
      state.success = false;
      state.failedCount = state.failedCount + 1;
    },
    OTP_RESET(state) {
      state.loading = false;
      state.digits = null;
      state.otp = "";
      state.success = false;
      state.error = false;
      state.failedCount = 0;
      state.errorMsg = "";
      state.accessToken = "";
    },
  },
});

export const loginActions = loginSlice.actions;

export default loginSlice;
