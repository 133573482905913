import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: false,
  err: null,
  usage: [],
};

const usageSlice = createSlice({
  name: REDUCER.USAGE,
  initialState,
  reducers: {
    REQUEST(state) {
      state.loading = true;
    },
    SUCCESS(state, action) {
      return { ...state, usage: action.payload, loading: false };
    },
    FAIL(state, action) {
      return { ...state, err: action.payload.err, loading: false };
    },
    CLEAR(state) {
      return state;
    },
  },
});

const usageActions = usageSlice.actions;
const usageReducer = usageSlice.reducer;

export { usageReducer, usageActions };
