/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  contacts: [],
};

const recentContactsSlice = createSlice({
  name: REDUCER.CONTACTS,
  initialState,
  reducers: {
    ADD(state, action) {
      if (state.contacts.includes(action.payload)) return;
      state.contacts = [action.payload, ...state.contacts.slice(0, 2)];
    },
    RESET(state) {
      state.contacts = [];
    },
  },
});

export const recentContactsActions = recentContactsSlice.actions;
export default recentContactsSlice;
