import {
  SETUP_CONSTANT,
  AGENT_TRACKING_APP_CODE,
} from "../config/endPointsConfig";
import {
  HomeIcon,
  MomoIcon,
  MoreIcon,
  ShopIcon,
} from "../assets/icons/svgIcons";

import DonutIcon from "../assets/icons/ic_donut_large.svg";
import FilterIcon from "../assets/icons/ic_filter_list.svg";
import BackIcon from "../assets/icons/ic_arrow_back.svg";
import ArrowRightIcon from "../assets/icons/ic_arrow_right.svg";
import CloseIcon from "../assets/icons/ic_close.svg";
import CloseIconWhite from "../assets/icons/ic_close_white.svg";
import ShoppingCart from "../assets/icons/ic_shopping_cart.svg";
import ReceiptIcon from "../assets/icons/ic_receipt.svg";
import RefreshIcon from "../assets/icons/ic_refresh.svg";
import Just4uIcon from "../assets/icons/ic_just4u.svg";
import Just4uEmptyIcon from "../assets/icons/ic_empty_j4u.svg";
import ArrowDownIcon from "../assets/icons/ic_arrow.svg";
import ArrowDownBlackIcon from "../assets/icons/ic_arrow_down_black.svg";
import BroadbandIcon from "../assets/icons/ic_broadband_black.svg";
import BroadbandShopIcon from "../assets/icons/ic_broadband_white.svg";
import BroadbandCardIcon from "../assets/icons/ic_broadband.svg";
import InfoBlackIcon from "../assets/icons/ic_info_black.svg";
import ZigiIcon from "../assets/icons/ic_zigi.svg";
import DownloadIcon from "../assets/icons/ic_download.svg";
import AddIcon from "../assets/icons/ic_ios_add.svg";
import AddIconYellow from "../assets/icons/ic_add_icon_yellow.svg";
import ShareIcon from "../assets/icons/ic_ios_share.svg";
import AppIcon from "../assets/images/app_icon.png";
import ArrowTop from "../assets/icons/ic_arrow_top.svg";
import ClockIcon from "../assets/icons/ic_clock.svg";
import DateRange from "../assets/icons/ic_date_range.svg";
import DateRangeWeek from "../assets/icons/ic_date_range_week.svg";
import DateRangeMonth from "../assets/icons/ic_date_range_month.svg";
import SendIcon from "../assets/icons/ic_send.svg";
import DoneIcon from "../assets/icons/ic_done.svg";
import ProfileImage from "../assets/images/profile.svg";
import Me2UIcon from "../assets/icons/ic_me2u.svg";
import PostpaidIcon from "../assets/icons/ic_postpaid.svg";
import UpdateIcon from "../assets/icons/ic_update.svg";
import ZigiImage from "../assets/images/zigi.svg";
import AsteriskIcon from "../assets/icons/ic_asterisk.svg";
import KebabIcon from "../assets/icons/ic_kebab.svg";
import WarningIconNew from "../assets/icons/ic_warning.svg";
import IndependenceDay from "../assets/images/independence.svg";
import HolidayBanner from "../assets/images/holiday_banner.svg";
import ModalErrorBg from "../assets/images/modal_error_bg.svg";
import { createTheme, makeStyles } from "@material-ui/core";

const API_CONST = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PUT: "PUT",
  ACCEPT: "*/*",
  CONNECTION: "keep-alive",
  AUTHORIZATION: (accessToken) => {
    return { Authorization: `Bearer ${accessToken}` };
  },
  X_COUNTRY_CODE: { "X-Country-Code": "gha" },
  PLATFORM: { platform: "pwa" },
  VERSION: { api_version: "v2" },
  OS: { os: "web" }, //update version when version is updated from core
};

const FETCH_OPTIONS_BASE = {
  cache: "default",
  method: API_CONST.GET,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Accept-Encoding": "gzip,deflate,br",
  },
};

const FETCH_OPTIONS = {
  getData: (_headers) => {
    return {
      ...FETCH_OPTIONS_BASE,
      method: API_CONST.GET,
      headers: { ...FETCH_OPTIONS_BASE.headers, ..._headers },
    };
  },
  postData: (_body, extras) => {
    return {
      ...FETCH_OPTIONS_BASE,
      method: API_CONST.POST,

      headers: { ...FETCH_OPTIONS_BASE.headers, ...extras },
      body: JSON.stringify(_body),
    };
  },
};

const DATE_FORMAT = {
  LONG_YEAR_FORMAT: "YYYY",
};

const GENERAL = {
  APP_NAME: "MyMTN Lite",
  SHOULD_SHOW_WALKTHROUGH: "shouldShowWalkThrough",
  CODE: "Code",
  NUMBER: "number",
  ADD: "Add",
  UPGRADE: "Upgrade",
  DISMISS: "Dismiss",
  INSTALL_MESSAGE: "Would you like to add myMTN Lite to the homescreen?",
  UPGRADE_MESSAGE: "Would you like to upgrade to the main app?",
  PLAY_STORE_LINK:
    "https://play.google.com/store/apps/details?id=com.mtngh.mymtn",
  APP_STORE_LINK: "https://apps.apple.com/gh/app/my-mtn-ghana/id1466214871",
  TERMS_LINK: "https://mtn.com.gh/personal/terms-and-conditions/",
  MOMO_PWA_LINK: "https://momoapp.mtn.com.gh",
  CUSTOMER_CARE_MSG:
    "Our Customer Care representatives are available 24 hours to attend to all your service needs.",
  MAX_NAME_COUNT: 40,
  PUK_INFO:
    "A PUK code is the PIN Unlocking Key delivered with your SIM card, which is used to unlock your SIM card when you have entered a wrong PIN code many times.",
  INSUFFICIENT_AIRTIME_MESSAGE:
    "Please top up your Airtime account to be able to complete this transaction",
  FLEXI: "FLEXI",
  APP_CODE: AGENT_TRACKING_APP_CODE,
  LOGIN_ERROR_MESSAGE:
    "Oops! There was a problem on our end. Please try  again later.",
  OTP_INVALID_MESSAGE:
    "Incorrect Code. Kindly re-enter code or request a new code.",
  OTP_TIMEOUT_MESSAGE: "Expired code. Please request a new code.",
  BUNDLE_HEADER: "Popular Bundle",
  ICON_ASSETS_URL: `${SETUP_CONSTANT.endpoints.assetsBaseURL}assets/icons/`,
  IMAGE_ASSETS_URL: `${SETUP_CONSTANT.endpoints.assetsBaseURL}assets/images/`,
  FONT_ASSETS_URL: `${SETUP_CONSTANT.endpoints.assetsBaseURL}assets/fonts/`,
  HOLIDAY_SUB_AMT: 10,
  IS_HOLIDAY: true,
};

const APP_ROUTES = {
  ROOT: "/",
  LOGIN: "/login",
  LOGOUT: "/logout",
  HOME: "/home",
  HOME_SLUG: "/home/:id",
  DASHBOARD: "/home",
  WILD_CARD: "*",
  ONBOARDING: "/onboarding",
  OTP: "/otp",
  BUY_LANDING: "/buy_landing",
  SELECTED_BUNDLE_LIST: "/selected_bundle",
  SHOP_LANDING: "/shop",
  REVIEW_PAY: "/review_pay",
  REVIEW_PAY_RESPONSE: "/review_pay_response",
  HELP: "/help",
  LEGAL: "/legal",
  PROFILE: "/profile",
  PRIVACY: "/privacy-policy",
  AIR_TIME_PAYMENT_DETAILS: "/airtime_payment_details",
  AGENT_REFERRAL: "/agent-referral",
  DETAILED_BALANCE: "/detailed-balance",
  Splash: "/splash",
  HE: "/he",
  MASHUP: "/shop/mashup",
  PIN_VALIDATION: "/review_pay/pin-validation",
  JUST4U: "/shop/just4u",
  BROADBAND: "/shop/broadband",
  BROADBAND_ACCOUNT: "/shop/broadband-account",
  CHAT: "/chat-bot",
  HISTORY_BY_ID: "/account-history/:id",
  HISTORY: "/account-history",
  MAINTENANCE: "/maintenance",
  HOLIDAY: "/holiday-offer",
};

const API_ROUTES = {
  BUNDLES: "bundles",
  PRODUCT_CATALOG: "catalogue?country=swz",
  CUSTOMER_BALANCES: "customerBalances",
};

const REDUCER = {
  AUTH: "auth",
  CONFIG: "config",
  LOGIN: "login",
  USER: "user_profile",
  BUNDLE: "bundle",
  HELP: "help",
  APP_PLATFORM: "platform",
  CUSTOMERBALANCES: "customerBalances",
  MODAL: "modal",
  AGENT_TRACKING: "trackAgent",
  PRODUCT_CATALOG: "productCatalog",
  NOTIFICATION: "notification",
  UPGRADE_NOTIFICATION: "upgrade",
  SUBSCRIPTIONS: "subscriptions",
  BUY_AGAIN: "buyAgain",
  SERVICE_WORKER: "service_worker",
  REWARD: "reward",
  CONTACTS: "contacts",
  FETCH_FLEXI: "flexi",
  VALIDATE_MSISDN: "validate",
  JUST4U: "just4u",
  BROADBAND: "broadband",
  AUTH_ERROR: "auth_error",
  HOLIDAY: "holiday",
  USAGE: "usage",
};

const APP_RESPONSE = {
  RESPONSE_SUCCESS: {
    title: "Enjoy your purchase",
    description:
      "Your purchase was successful. This purchase does not expire. You will receive an SMS for confirmation soon",
  },
  RESPONSE_SUCCESS_MOMO: {
    title: "Approval Required",
    description:
      "To complete purchase, you will receive a prompt to enter your MoMo PIN to confirm this transaction",
  },
  RESPONSE_SUCCESS_J4U: {
    title: "Enjoy your purchase",
    description:
      "Your purchase was successful. This purchase expires in X days. You will receive an SMS for confirmation soon",
  },
  RESPONSE_FAILURE: {
    title: "Well, that was unexpected…",
    description:
      "We couldn't complete your purchase due to a technical error. Please try again later.",
  },
  HOLIDAY_SUB_SUCCESS: {
    title: "Success",
    description:
      "You have joined the Holiday special offer. You have received 2.5GB of data valid for 24 hours",
  },
  HOLIDAY_UNSUB_SUCCESS: {
    title: "Successfully Unsubscribed",
    description: "You have unsubscribed from Holiday Bundle.",
  },
  HOLIDAY_SUB_FAILURE: {
    title: "Insufficient Airtime",
    description:
      "Kindly recharge your balance to get 2.5gb. This offer is only valid today.",
  },
};

const OS_PLATFORMS = {
  ANDROID: "android",
  IOS: "iOS",
  WINDOWS: "windows",
  MAC_OS: "mac",
  LINUX: "linux",
};

const MODAL_ROUTES = {
  INSTALL: "Install",
  UPGRADE: "Upgrade",
};

const RECOMMENDED_BUNDLE_LIST = {
  ract_data_ghc_3: "ract_data_ghc_3",
  ract_social_ghc_5: "ract_social_ghc_5",
  ract_video_ghc_5: "ract_video_ghc_5",
};

const BUNDLE_TYPES = {
  SOCIAL: "social",
  DATA: "data",
  MIDNIGHT: "midnight",
  VIDEO: "video",
  KOKROKOO: "kokrokoo",
  MASHUP: "mashup",
  JUST4U: "just4u",
  BROADBAND: "broadband",
  SMS: "sms",
  VOICE: "voice",
  AIRTIME: "airtime",
  POSTPAID: "postpaid",
  ME2U: "me2u",
};

const SUBSCRIPTION_TYPES = {
  PREPAID: "prepaid",
  POSTPAID: "postpaid",
};

const ENVIRONMENTS = {
  PROD: "PRODUCTION",
  STAGE: "STAGING",
  DEV: "DEVELOPMENT",
};

const APP_SECTION = {
  GSM: "GSM",
  MOMO: "MoMo",
};

const PAYMENT_METHOD = {
  AIRTIME: "airtime",
  MOMO: "momo",
};
const RECIPIENT = {
  SELF: "self",
  OTHERS: "others",
};

const CONSTANT_EXTERNAL = {
  walkthrough: {
    heading_1: "Light and Fast",
    description_1:
      "Add myMTN Lite icon as a shortcut to your device screen and launch conveniently anytime.",
    heading_2: "Convenient Control",
    description_2:
      "Shop for data or airtime, manage your MoMo and broadband accounts on myMTN Lite",
    heading_3: "Free to use",
    description_3:
      "Use myMTN Lite for free as it does not require your data bundle. However, your data connection must be on.",
  },
};

const ICONS = {
  PhoneIcon: `${GENERAL.ICON_ASSETS_URL}ic_phone_in_talk.svg`,
  SmsIcon: `${GENERAL.ICON_ASSETS_URL}ic_forum.svg`,
  VoiceIcon: `${GENERAL.ICON_ASSETS_URL}ic_keyboard_voice.svg`,
  WifiIcon: `${GENERAL.ICON_ASSETS_URL}ic_wifi.svg`,
  WifiBlueIcon: `${GENERAL.ICON_ASSETS_URL}blue_data.svg`,
  WifiWhiteIcon: `${GENERAL.ICON_ASSETS_URL}white_data.svg`,
  WifiIminiIcon: `${GENERAL.ICON_ASSETS_URL}ic_wifi_mini.png`,
  PhoneWhiteIcon: `${GENERAL.ICON_ASSETS_URL}white_phone.svg`,
  InfoIcon: `${GENERAL.ICON_ASSETS_URL}ic_info.svg`,
  ErrorIcon: `${GENERAL.ICON_ASSETS_URL}ic_error.svg`,
  RouterIcon: `${GENERAL.ICON_ASSETS_URL}ic_router.svg`,
  WarningIcon: `${GENERAL.ICON_ASSETS_URL}ic_warning.svg`,
  VideoIcon: `${GENERAL.ICON_ASSETS_URL}ic_video_changed.png`,
  DstvIcon: `${GENERAL.ICON_ASSETS_URL}ic_dstv.svg`,
  IrocoIcon: `${GENERAL.ICON_ASSETS_URL}ic_iroko.svg`,
  YoutubeIcon: `${GENERAL.ICON_ASSETS_URL}ic_youtube.svg`,
  MidnightIcon: `${GENERAL.ICON_ASSETS_URL}ic_midnight.svg`,
  KokroKooIcon: `${GENERAL.ICON_ASSETS_URL}ic_kokorokoo.svg`,
  DataBundleIcon: `${GENERAL.ICON_ASSETS_URL}ic_data_bundle.svg`,
  OtherBundleIcon: `${GENERAL.ICON_ASSETS_URL}ic_other_bundle.svg`,
  WhatsappIcon: `${GENERAL.ICON_ASSETS_URL}ic_whatsapp.svg`,
  PhoneFlipIcon: `${GENERAL.ICON_ASSETS_URL}ic_phone.svg`,
  EmailIcon: `${GENERAL.ICON_ASSETS_URL}ic_email.svg`,
  OutlineInfoIcon: `${GENERAL.ICON_ASSETS_URL}ic_info_outline.svg`,
  TikTokIcon: `${GENERAL.ICON_ASSETS_URL}tik-tok 1.svg`,
  WhatsappFillIcon: `${GENERAL.ICON_ASSETS_URL}bi_whatsapp.svg`,
  InstagramIcon: `${GENERAL.ICON_ASSETS_URL}akar-icons_instagram-fill.svg`,
  FacebookIcon: `${GENERAL.ICON_ASSETS_URL}ant-design_facebook-filled.svg`,
  VoiceIconWhite: `${GENERAL.ICON_ASSETS_URL}ic_mic.svg`,
  AirtimeIcon: `${GENERAL.ICON_ASSETS_URL}ic_sim_card.svg`,
  IosAddIcon: `${GENERAL.ICON_ASSETS_URL}ic_ios_add.svg`,
  IosShareIcon: `${GENERAL.ICON_ASSETS_URL}ic_ios_share.svg`,
  SocialMediaBundleIcon: `${GENERAL.ICON_ASSETS_URL}ic_social.png`,
  OfferIcon: `${GENERAL.ICON_ASSETS_URL}ic_offer.png`,
  MashupIcon: `${GENERAL.ICON_ASSETS_URL}ic_mashup.svg`,
  TwitterIcon: `${GENERAL.ICON_ASSETS_URL}ic_twitter.svg`,
  WechatIcon: `${GENERAL.ICON_ASSETS_URL}ic_wechat.svg`,
  ForwardIcon: `${GENERAL.ICON_ASSETS_URL}forward_icon.svg`,
  DonutIcon,
  FilterIcon,
  BackIcon,
  ArrowRightIcon,
  CloseIcon,
  CloseIconWhite,
  ShoppingCart,
  ReceiptIcon,
  RefreshIcon,
  Just4uIcon,
  Just4uEmptyIcon,
  ArrowDownIcon,
  BroadbandIcon,
  BroadbandCardIcon,
  BroadbandShopIcon,
  InfoBlackIcon,
  ZigiIcon,
  DownloadIcon,
  AddIcon,
  ShareIcon,
  ArrowTop,
  ArrowDownBlackIcon,
  ClockIcon,
  DateRange,
  SendIcon,
  DateRangeWeek,
  DateRangeMonth,
  DoneIcon,
  PostpaidIcon,
  Me2UIcon,
  UpdateIcon,
  AddIconYellow,
  AsteriskIcon,
  KebabIcon,
  WarningIconNew,
};

const IMAGES = {
  Logo: `${GENERAL.IMAGE_ASSETS_URL}logo.png`,
  MoreHeader: `${GENERAL.IMAGE_ASSETS_URL}more_header.svg`,
  ErrorBg: `${GENERAL.IMAGE_ASSETS_URL}error_response_bg.png`,
  SuccessBg: `${GENERAL.IMAGE_ASSETS_URL}success_response_bg.png`,
  Face: `${GENERAL.IMAGE_ASSETS_URL}face.svg`,
  SplashBg: `${GENERAL.IMAGE_ASSETS_URL}splash_background.png`,
  BonusBg: `${GENERAL.IMAGE_ASSETS_URL}bonus_bg.svg`,
  BonusImg: `${GENERAL.IMAGE_ASSETS_URL}bonus_img.svg`,
  MyMtnLogo: `${GENERAL.IMAGE_ASSETS_URL}my_mtn_logo.svg`,
  SplashImage1: `${GENERAL.IMAGE_ASSETS_URL}free_to_use.png`,
  SplashImage2: `${GENERAL.IMAGE_ASSETS_URL}light_n_fast.png`,
  SplashImage3: `${GENERAL.IMAGE_ASSETS_URL}convenient_ctrl.png`,
  NativeLogo: `${GENERAL.IMAGE_ASSETS_URL}native_logo.png`,
  FlexPromoBg: `${GENERAL.IMAGE_ASSETS_URL}background_promo.png`,
  SocialPromoBg: `${GENERAL.IMAGE_ASSETS_URL}background_promo2.png`,
  ApprovalBg: `${GENERAL.IMAGE_ASSETS_URL}approval_bg.png`,
  LadyAvatar: `${GENERAL.IMAGE_ASSETS_URL}lady_on_call.svg`,
  AppIcon,
  ProfileImage,
  ZigiImage,
  IndependenceDay,
  HolidayBanner,
  ModalErrorBg,
};

const MORE_MENU_ITEMS = [
  {
    title: "Profile",
    path: APP_ROUTES.PROFILE,
  },
  {
    title: "eSIM Request",
    link: "https://esimrequest.mtn.com.gh/",
  },
  {
    title: "Help",
    path: APP_ROUTES.HELP,
  },
  {
    title: "Legal",
    path: APP_ROUTES.PRIVACY,
  },
  {
    title: "Agent Referral Code",
    path: APP_ROUTES.AGENT_REFERRAL,
  },
  {
    title: "Logout",
  },
];

const MORE_MENU_ITEMS_FILTERED = [
  {
    title: "Profile",
    path: APP_ROUTES.PROFILE,
  },
  {
    title: "eSIM Request",
    link: "https://esimrequest.mtn.com.gh/",
  },
  {
    title: "Help",
    path: APP_ROUTES.HELP,
  },
  {
    title: "Legal",
    path: APP_ROUTES.PRIVACY,
  },
  {
    title: "Logout",
  },
];

const HOLIDAY = {
  SUBSCRIBE: "SUBSCRIBE",
  UNSUBSCRIBE: "UNSUBSCRIBE",
  PROVIDER: "special",
};

const materialInputStyles = makeStyles(() => ({
  root: {
    "& label.Mui-error": {
      color: "#BE1E50",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#333333",
        color: "#333333",
      },
      "&:hover fieldset": {
        border: "1px solid #333333",
      },
      "&.Mui-error fieldset": {
        borderColor: "#AFAFAF",
      },
    },
    "& label.MuiFormControlLabel-root": {
      height: 30,
    },
    "& .MuiTypography-root": {
      fontSize: 14,
    },
  },
}));

const useStyles = makeStyles({
  root: {
    width: "100%",
    "& .MuiBottomNavigationAction-root.Mui-selected": {
      color: "#FFCB05",
    },
    "& .MuiBottomNavigationAction-root": {
      color: "#000",
    },
  },
});

const materialTheme = createTheme({
  palette: {
    primary: { main: "#FFCB05" },
    secondary: { main: "#333" },
  },
  typography: {
    useNextVariants: true,
    fontFamily: '"MTNBrighterSans-Regular"',
    fontSize: 12,
  },
});

const pages = [
  { id: "dashboard", label: "Home", icon: HomeIcon, route: "/home/dashboard" },
  { id: "shop", label: "Shop", icon: ShopIcon, route: "/home/shop" },
  { id: "momo", label: "Momo", icon: MomoIcon, route: "" },
  { id: "more", label: "More", icon: MoreIcon, route: "/home/more" },
];

export {
  API_CONST,
  FETCH_OPTIONS_BASE,
  FETCH_OPTIONS,
  DATE_FORMAT,
  GENERAL,
  APP_RESPONSE,
  APP_ROUTES,
  API_ROUTES,
  REDUCER,
  OS_PLATFORMS,
  MODAL_ROUTES,
  RECOMMENDED_BUNDLE_LIST,
  BUNDLE_TYPES,
  SUBSCRIPTION_TYPES,
  ENVIRONMENTS,
  APP_SECTION,
  PAYMENT_METHOD,
  RECIPIENT,
  CONSTANT_EXTERNAL,
  ICONS,
  IMAGES,
  MORE_MENU_ITEMS,
  MORE_MENU_ITEMS_FILTERED,
  HOLIDAY,
  materialInputStyles,
  useStyles,
  materialTheme,
  pages,
};
