import ENDPOINTS from "./appConfig";

/**
 * available enviroments are
 *  DEVELOPMENT,  STAGING, PRODUCTION
 */
export const currenEnv = "PRODUCTION";

export const AGENT_TRACKING_APP_CODE = "AP99335";

export const SETUP_CONSTANT = ENDPOINTS.getEndpoints(currenEnv);
