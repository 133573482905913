const AnalyticsConfig = {
  id: "mymtn-gh",
  channel: "web",
  environment: "live",
  endpoints: [
    "https://transaction.mymtnlite.com.gh/api/v2/sendToAnalytics",
  ],
  logging: false,
  silentMode: false,
};

export default AnalyticsConfig;
