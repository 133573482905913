/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  showCount: 0,
};

const upgradeSlice = createSlice({
  name: REDUCER.UPGRADE_NOTIFICATION,
  initialState,
  reducers: {
    TOGGLE(state) {
      state.showCount = state.showCount + 1;
    },
    RESET_NOTIFICATION(state) {
      state.showCount = 0;
    },
  },
});

export const upgradeActions = upgradeSlice.actions;
export default upgradeSlice;
