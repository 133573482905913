import React, { useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { ICONS } from "../../constants/appConstants";
import ThemeContext from "../../context/themeContext";
import { dismissNotification } from "../../persistance/redux";
import * as s from "../../styles/globalStyles";
import { Button } from "./Buttons";

const UpdateBottomSheet = ({
  serviceWorkerUpdated,
  onUpdateClicked = () => {},
}) => {
  const { theme: t } = useContext(ThemeContext);
  const dispatch = useDispatch();

  const handleOnCLick = useCallback(() => {
    onUpdateClicked();
  }, [onUpdateClicked]);

  return (
    <s.StyledScreenOverflow
      t={t}
      showOverflow={serviceWorkerUpdated}
      onClick={() => dispatch(dismissNotification())}
    >
      <s.StyledOverflow
        t={t}
        showOverflow={serviceWorkerUpdated}
        style={{ padding: t.spacing.f5 }}
      >
        <s.Container flex={1} ai={"center"}>
          <img
            src={ICONS.UpdateIcon}
            width={"56px"}
            height={"72px"}
            alt={"update icon"}
          />
          <s.SpacerSmall t={t} />
          <s.TextTitle t={t} className={"mtn-medium"}>
            New Update!
          </s.TextTitle>
          <s.SpacerXSmall t={t} />
          <s.TextCaption t={t} style={{ textAlign: "center" }}>
            We have added a few more improvements to make myMTN Lite better and
            faster. Life is richer on MTN.
          </s.TextCaption>
          <s.SpacerSmall t={t} />
          <Button
            title={"OK"}
            style={{
              boxShadow: "none",
              height: "40px",
              textTransform: "inherit",
              fontSize: t.size.f4,
              width: "90%",
            }}
            action={handleOnCLick}
          />
        </s.Container>
      </s.StyledOverflow>
    </s.StyledScreenOverflow>
  );
};
export default UpdateBottomSheet;
