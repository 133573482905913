/* istanbul ignore file */
import { recentContactsActions } from "./recentContactsSlice";

export const addContact = (msisdn = "") => {
  return (dispatch) => {
    dispatch(recentContactsActions.ADD(msisdn));
  };
};

export const clearContacts = () => {
  return (dispatch) => {
    dispatch(recentContactsActions.RESET());
  };
};
