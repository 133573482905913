const ENDPOINTS = {
  getEndpoints: (_currenEnv) => {
    switch (_currenEnv) {
      case "STAGING":
        return {
          endpoints: {
            auth: "https://transaction.staging.mymtnlite.com.gh/api/v2/",
            shop: "https://shop.staging.mymtnlite.com.gh/api/v2/",
            transaction: "https://transaction.staging.mymtnlite.com.gh/api/v2/",
            help: "https://help.staging.mymtnlite.com.gh/api/v2/",
            analytics:
              "https://analytics.staging.mymtnlite.com.gh/proxy/v2/SendToAnalytics",
            logout: "https://pwa.staging.mymtnlite.com.gh/logout",
            auth0RedirectUrl: "https://pwa.staging.mymtnlite.com.gh/login",
            // logout: "http://localhost:3000/logout",
            // auth0RedirectUrl: "http://localhost:3000/login",
            assetsBaseURL: "https://assets.staging.mymtnlite.com.gh/",
            rewardBaseUrl: "https://reward.staging.mymtnlite.com.gh/",
            broadbandBaseUrl: "https://broadband.staging.mymtnlite.com.gh/",
            frontendCdn: "https://frontend-cdn.staging.mymtnlite.com.gh/",
            agentTracking:
              "https://agent-tracking.staging.mymtnlite.com.gh/api/v2/",
            chatbotUrl:
              "https://chatbot-stg.mtn.com.gh/bm/api/bot/custom/mymtnlite?new_session=true&k=ccm2NvBxbsS&hl=en-US",
          },
          tracker: {
            tracker_id: "core",
            tracker_env: "dev",
          },
        };
      case "PRODUCTION":
        return {
          endpoints: {
            auth: "https://transaction.mymtnlite.com.gh/api/v2/",
            shop: "https://shop.mymtnlite.com.gh/api/v2/",
            transaction: "https://transaction.mymtnlite.com.gh/api/v2/",
            help: "https://help.mymtnlite.com.gh/api/v2/",
            analytics:
              "https://analytics.mymtnlite.com.gh/proxy/v2/SendToAnalytics",
            logout: "https://mymtnlite.com.gh/logout",
            auth0RedirectUrl: "https://mymtnlite.com.gh/login",
            assetsBaseURL: "https://assets.mymtnlite.com.gh/",
            rewardBaseUrl: "https://reward.mymtnlite.com.gh/",
            broadbandBaseUrl: "https://broadband.mymtnlite.com.gh/",
            frontendCdn: "https://frontend-cdn.mymtnlite.com.gh/",
            agentTracking: "https://agent-tracking.mymtnlite.com.gh/api/v2/",
            chatbotUrl:
              "https://chatbot-prod.mtn.com.gh/bm/api/bot/custom/mymtnlite?new_session=true&k=MQ8ASHmK3x&hl=en-US",
          },
          tracker: {
            tracker_id: "core",
            tracker_env: "live",
          },
        };

      default:
        return {
          endpoints: {
            auth: "https://transaction.dev.mymtnlite.com.gh/api/v2/",
            shop: "https://shop.dev.mymtnlite.com.gh/api/v2/",
            transaction: "https://transaction.dev.mymtnlite.com.gh/api/v2/",
            help: "https://help.dev.mymtnlite.com.gh/api/v2/",
            analytics:
              "https://analytics.dev.mymtnlite.com.gh/proxy/v2/SendToAnalytics",
            logout: "https://pwa.dev.mymtnlite.com.gh/logout",
            auth0RedirectUrl: "https://pwa.dev.mymtnlite.com.gh/login",
            assetsBaseURL: "https://assets.dev.mymtnlite.com.gh/",
            rewardBaseUrl: "https://reward.dev.mymtnlite.com.gh/",
            broadbandBaseUrl: "https://broadband.dev.mymtnlite.com.gh/",
            // logout: "http://localhost:3000/logout",
            // auth0RedirectUrl: "http://localhost:3000/login",
            frontendCdn: "https://frontend-cdn.dev.mymtnlite.com.gh/",
            agentTracking:
              "https://agent-tracking.dev.mymtnlite.com.gh/api/v2/",
            chatbotUrl:
              "https://chatbot-dev.mtn.com.gh/bm/api/bot/custom/mymtnlite?new_session=true&k=ccm2NvBxbsS&hl=en-US",
          },
          tracker: {
            tracker_id: "core",
            tracker_env: "dev",
          },
        };
    }
  },
};

export default ENDPOINTS;
