/* istanbul ignore file */
import { notificationActions } from "./notificationSlice";
export const showNotification = () => {
  return (dispatch) => {
    const payload = {
      shouldShowNotification: true,
      hasShownAlready: false,
    };
    dispatch(notificationActions.TOGGLE(payload));
  };
};

export const dismissNotification = () => {
  return (dispatch) => {
    const payload = {
      shouldShowNotification: false,
      hasShownAlready: true,
    };
    dispatch(notificationActions.TOGGLE(payload));
  };
};

export const resetNotification = () => {
  return (dispatch) => {
    const payload = {
      shouldShowNotification: false,
      hasShownAlready: false,
    };
    dispatch(notificationActions.RESET_NOTIFICATION(payload));
  };
};
