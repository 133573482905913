/* istanbul ignore file */
import { User } from "../../models/User";
import { authActions } from "./authSlice";

export const clearAuth = () => {
  return (dispatch) => {
    dispatch(authActions.CLEAR_AUTH());
  };
};

export const setIsRedirect = (isRedirect) => {
  return (dispatch) => {
    dispatch(authActions.SET_ISREDIRECT(isRedirect));
  };
};

export const updatePhotoUrl = (url) => {
  return (dispatch) => {
    dispatch(authActions.SET_PHOTO_URL({ photo_url: url }));
  };
};

export const removePhotoUrl = () => {
  return (dispatch) => {
    dispatch(authActions.REMOVE_PHOTO_URL());
  };
};

export const setUser = (user) => {
  let _user = new User(user);
  return (dispatch) => {
    dispatch(authActions.AUTH_SUCCESS(_user));
  };
};
