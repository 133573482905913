// React imports
import React, { useState } from "react";
// Constants
import THEME from "../constants/themeConstants";
// Theme
import Theme from "../styles/theme";
import useWindowSize from "../hooks/useWindowSize";
import { useAuth0 } from "@auth0/auth0-react";

const ThemeContext = React.createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(Theme.set(THEME.GENERIC));
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 768 ? true : false;
  const isDesktop = windowSize.width > 768 ? true : false;
  const isTablet =
    windowSize.width > 800 && windowSize.width < 1024 ? true : false;
  const isDesktopX = windowSize.width >= 1024 ? true : false;

  const {
    isAuthenticated,
    getAccessTokenSilently,
    user: auth0User,
  } = useAuth0();

  const switchTheme = (theme) => {
    setTheme(Theme.set(theme));
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        switchTheme,
        isMobile,
        isDesktop,
        isTablet,
        isDesktopX,
        isAuthenticated,
        getAccessTokenSilently,
        auth0User,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
