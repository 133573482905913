/* istanbul ignore file */
import { just4uActions } from "./just4uSlice";
import { SETUP_CONSTANT } from "../../../config/endPointsConfig";
import { API_CONST, FETCH_OPTIONS } from "../../../constants/appConstants";

export const getCustomerPromos = (getAccessTokenSilently) => {
  return async (dispatch) => {
    dispatch(just4uActions.REQUEST());
    const url = `${SETUP_CONSTANT.endpoints.shop}customerPromos`;

    const token = await getAccessTokenSilently();

    if (token == null || token === "") {
      return;
    }

    const headers = {
      ...API_CONST.AUTHORIZATION(token),
      ...API_CONST.X_COUNTRY_CODE,
    };

    try {
      const response = await fetch(url, FETCH_OPTIONS.getData(headers));
      response
        .json()
        .then((data) => {
          if (response.status !== 200) {
            dispatch(just4uActions.FAILURE());
            console.log("here");
          } else {
            const payload = {
              categories: data,
              description:
                "Packages curated Just4U based on your usage patterns",
              icon: "just4u",
              id: 0,
              index: 0,
              name: "Just4U",
            };
            dispatch(just4uActions.SUCCESS(payload));
          }
        })
        .catch((e) => {
          dispatch(just4uActions.FAILURE());
        });
    } catch (ex) {
      dispatch(just4uActions.FAILURE());
    }
  };
};

export const clearCustomerPromos = () => {
  return (dispatch) => {
    dispatch(just4uActions.CLEAR());
  };
};
