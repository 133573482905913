/* istanbul ignore file */
import { subscriptionActions } from "./subscriptionsSlice";
import {
  API_CONST,
  FETCH_OPTIONS,
  APP_ROUTES,
  APP_RESPONSE,
  RECIPIENT,
  APP_SECTION,
  PAYMENT_METHOD,
  GENERAL,
} from "../../../constants/appConstants";
import { SETUP_CONSTANT } from "../../../config/endPointsConfig";
import {
  provision_bundle_fail,
  bundlePurchase,
  shopSuccessLanding,
} from "../../../utils/trackerFunctions";
import { addContact } from "../recentContacts/recentContactsAction";

export const buyBundle = (body, history, getAccessTokenSilently) => {
  return async (dispatch) => {
    const token = await getAccessTokenSilently();

    if (token == null || token === "") {
      return;
    }

    if (body === undefined || Object.keys(body).length === 0) {
      return;
    }

    dispatch(subscriptionActions.REQUEST());

    const url = `${SETUP_CONSTANT.endpoints.transaction}subscription`;
    const headers = {
      ...API_CONST.X_COUNTRY_CODE,
      ...API_CONST.AUTHORIZATION(token),
    };

    const isMashup = body?.productId?.toLowerCase()?.includes("mashup");

    try {
      const response = await fetch(
        url,
        FETCH_OPTIONS.postData(generateRequestBody(body, isMashup), headers)
      );
      response
        .json()
        .then((data) => {
          if (response.status === 200) {
            handleSuccess(
              body,
              getAccessTokenSilently,
              dispatch,
              data,
              history
            );
          } else {
            handleFailure(
              data.message,
              getAccessTokenSilently,
              dispatch,
              history
            );
          }
        })
        .catch((e) => {
          handleFailure(e.message, getAccessTokenSilently, dispatch, history);
        });
    } catch (e) {
      dispatch(
        subscriptionActions.FAILED({ message: "Network error occured" })
      );
    }
  };
};

export const clearBuyAgainBundles = () => {
  return async (dispatch) => {
    dispatch(subscriptionActions.RESET());
  };
};

const handleSuccess = (
  body,
  getAccessTokenSilently,
  dispatch,
  data,
  history
) => {
  //Analytics event
  shopSuccessLanding(getAccessTokenSilently);

  if (!body.beneficiaryId) {
    dispatch(
      subscriptionActions.SUCCESS(
        body?.isJust4u
          ? {}
          : {
              ...data,
              data_value: body?.dataValue,
              nact_code: body?.nact_code,
            }
      )
    );
  } else {
    dispatch(addContact(body?.beneficiaryId));
  }

  performAnalytics(
    body,
    data?.transactionId,
    data?.subscriptionName,
    getAccessTokenSilently
  );

  history.push(APP_ROUTES.REVIEW_PAY_RESPONSE, {
    responseDetails: {
      isError: body?.payment_method === "momo" ? 2 : 1,
      title:
        body?.payment_method === "momo"
          ? APP_RESPONSE.RESPONSE_SUCCESS_MOMO.title
          : `${
              body?.isJust4u
                ? `Offer purchase request submitted`
                : APP_RESPONSE.RESPONSE_SUCCESS.title
            }`,
      description:
        body?.payment_method === "momo"
          ? APP_RESPONSE.RESPONSE_SUCCESS_MOMO.description
          : `${
              body?.isJust4u
                ? `You shall receive confirmation SMS after successful activation of bundle.`
                : APP_RESPONSE.RESPONSE_SUCCESS.description
            }`,
      success: true,
      shouldShowIncentiveNotice: data?.shouldShowIncentiveNotice,
    },
  });
};

const handleFailure = (message, getAccessTokenSilently, dispatch, history) => {
  dispatch(subscriptionActions.FAILED({ message: message }));
  provision_bundle_fail(getAccessTokenSilently);
  history.push(APP_ROUTES.REVIEW_PAY_RESPONSE, {
    responseDetails: {
      isError: 0,
      title: APP_RESPONSE.RESPONSE_FAILURE.title,
      description: APP_RESPONSE.RESPONSE_FAILURE.description,
      success: false,
    },
  });
};

const generateRequestBody = (body, isMashup) => {
  return {
    product_id: body?.productId,
    product_type: body?.type,
    ...((body?.isJust4u || body.beneficiaryId) && {
      beneficiary_id: body?.beneficiaryId,
    }),
    payment_method: body?.payment_method,
    ...(body?.type === "FLEXI" && {
      price: body?.price,
    }),
    ...((body?.isJust4u || isMashup) && {
      provider: isMashup ? "pulse" : "just4u",
    }),
  };
};

const performAnalytics = (
  body = {},
  transactionId = "",
  subscriptionName = "",
  getAccessTokenSilently = () => {}
) => {
  bundlePurchase(
    body?.payment_method === PAYMENT_METHOD.AIRTIME
      ? PAYMENT_METHOD.AIRTIME
      : PAYMENT_METHOD.MOMO,
    GENERAL.FLEXI ? true : false,
    body?.beneficiaryId ? RECIPIENT.OTHERS : RECIPIENT.SELF,
    body?.productId,
    body?.payment_method === PAYMENT_METHOD.AIRTIME
      ? APP_SECTION.GSM
      : APP_SECTION.MOMO,
    body?.type,
    subscriptionName,
    body?.dataValue,
    body?.price,
    1,
    transactionId,
    getAccessTokenSilently
  );
};
