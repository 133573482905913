/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: false,
  accounts: {},
  balances: [],
  isError: false,
  errorMsg: "",
  accountArray: [],
  catalog_loading: false,
  catalog: [],
  catalog_error: false,
  subscription_loading: false,
};

const broadbandSlice = createSlice({
  name: REDUCER.CUSTOMERBALANCES,
  initialState,
  reducers: {
    REQUEST(state) {
      state.loading = true;
    },
    SUCCESS(state, action) {
      state.loading = false;
      state.isError = false;
      if (action.payload.type === "account") {
        state.accounts = action.payload.data;
      } else if (action.payload.type === "accounts") {
        state.accountArray = action.payload.data;
      } else {
        state.balances = action.payload.data;
      }
    },
    FAILED(state, action) {
      state.loading = false;
      state.isError = true;
      state.errorMsg = action.payload;
    },
    CLEAR(state) {
      state.loading = false;
      state.errorMsg = "";
      state.isError = false;
      state.accounts = {};
      state.balances = {};
      state.accountArray = [];
      state.catalog_loading = false;
      state.catalog = [];
      state.catalog_error = true;
    },
    CATALOG_REQUEST(state) {
      state.catalog_loading = true;
    },
    CATALOG_SUCCESS(state, action) {
      state.catalog_loading = false;
      state.catalog = action.payload;
      state.catalog_error = false;
    },
    CATALOG_FAILURE(state) {
      state.catalog_loading = false;
      state.catalog_error = true;
    },
    SUBSCRIPTION_REQUEST(state) {
      state.subscription_loading = true;
    },
    SUBSCRIPTION_SUCCESS(state) {
      state.subscription_loading = false;
    },
    SUBSCRIPTION_FAILED(state) {
      state.subscription_loading = false;
    },
  },
});

export const broadbandActions = broadbandSlice.actions;

export default broadbandSlice;
