/* istanbul ignore file */
import {
  API_CONST,
  APP_RESPONSE,
  APP_ROUTES,
  FETCH_OPTIONS,
} from "../../../constants/appConstants";
import { SETUP_CONSTANT } from "../../../config/endPointsConfig";
import { broadbandActions } from "./broadbandSlice";

export const getBroadbandAccounts = (getAccessTokenSilently) => {
  return async (dispatch) => {
    try {
      dispatch(broadbandActions.REQUEST());

      const url = `${SETUP_CONSTANT.endpoints.broadbandBaseUrl}api/broadband/getnumber`;
      const token = await getAccessTokenSilently();
      if (token == null || token === "") {
        return;
      }

      const response = await fetch(
        url,
        FETCH_OPTIONS.getData({
          ...API_CONST.AUTHORIZATION(token),
          ...API_CONST.X_COUNTRY_CODE,
        })
      );

      response
        .json()
        .then((data) => {
          if (
            response.status === 200 &&
            data?.httpStatus !== "INTERNAL_SERVER_ERROR"
          ) {
            if (data?.accounts === null) {
              dispatch(
                broadbandActions.SUCCESS({
                  type: "account",
                  data: {
                    name: "Broadband",
                    display_name: "GET BROADBAND HERE",
                    bonus_display: "0 ACCOUNTS LINKED",
                    icon: "broadband",
                    value: 1,
                    link: "https://broadband.mtn.com.gh/",
                  },
                })
              );
            } else {
              if (data?.accounts?.length === 1) {
                dispatch(
                  getBroadbandBalances(
                    data?.accounts[0]?.msisdn,
                    getAccessTokenSilently
                  )
                );
                dispatch(
                  broadbandActions.SUCCESS({
                    type: "accounts",
                    data: data.accounts,
                  })
                );
              } else {
                dispatch(
                  broadbandActions.SUCCESS({
                    type: "account",
                    data: {
                      name: "Broadband",
                      display_name: `${data?.accounts?.length} Accounts`,
                      bonus_display: "TAP TO VIEW BALANCES",
                      icon: "broadband",
                      value: 1,
                    },
                  })
                );
                dispatch(
                  broadbandActions.SUCCESS({
                    type: "accounts",
                    data: data?.accounts,
                  })
                );
              }
            }
          } else {
            throw new Error(
              "Broadband account request failed: " + data.httpStatus
            );
          }
        })
        .catch((e) => {
          dispatch(broadbandActions.FAILED(e.message));
        });
    } catch (error) {
      dispatch(broadbandActions.FAILED(error.message));
    }
  };
};

export const getBroadbandBalances = (msisdn, getAccessTokenSilently) => {
  return async (dispatch) => {
    try {
      dispatch(broadbandActions.REQUEST());

      const url = `${SETUP_CONSTANT.endpoints.broadbandBaseUrl}api/broadband/balances?broadbandMsisdn=${msisdn}`;

      const token = await getAccessTokenSilently();
      if (token == null || token === "") {
        return;
      }

      const response = await fetch(
        url,
        FETCH_OPTIONS.getData({
          ...API_CONST.AUTHORIZATION(token),
          ...API_CONST.X_COUNTRY_CODE,
        })
      );
      response
        .json()
        .then((data) => {
          if (response.status !== 200) return;

          if (data?.statusCode === "3001") {
            dispatch(broadbandActions.FAILED(data?.message));
          }

          const data_balance = data?.balance?.data[0];
          const bonus = data?.balance?.bonus[0]?.display_name;

          if (!data_balance && !bonus) return;
          dispatch(
            broadbandActions.SUCCESS({
              type: "account",
              data: {
                bonus_display: `BONUS: ${bonus}`,
                ...data_balance,
                name: "Broadband",
              },
            })
          );
          dispatch(
            broadbandActions.SUCCESS({ type: "balance", data: data?.balance })
          );
        })
        .catch((e) => {
          dispatch(broadbandActions.FAILED(e.message));
        });
    } catch (error) {
      dispatch(broadbandActions.FAILED(error.message));
    }
  };
};

export const getBroadbandSubscriptions = (token, nact_code) => {
  const url = `${SETUP_CONSTANT.endpoints.broadbandBaseUrl}api/broadband/catalogue?category=${nact_code}`;

  return async (dispatch) => {
    if (token == null || token === "") {
      return;
    }

    const headers = {
      ...API_CONST.AUTHORIZATION(token),
      ...API_CONST.X_COUNTRY_CODE,
    };

    dispatch(broadbandActions.CATALOG_REQUEST());

    try {
      const response = await fetch(url, FETCH_OPTIONS.getData(headers));
      response
        .json()
        .then((data) => {
          if (response.status !== 200) return;
          if (data?.success === true) {
            dispatch(broadbandActions.CATALOG_SUCCESS(data?.products));
          } else {
            dispatch(broadbandActions.CATALOG_FAILURE());
          }
        })
        .catch((_) => {
          dispatch(broadbandActions.CATALOG_FAILURE());
        });
    } catch (_) {
      dispatch(broadbandActions.CATALOG_FAILURE());
    }
  };
};

export const provisionBroadbandBundle = (token, body, history) => {
  const url = `${SETUP_CONSTANT.endpoints.broadbandBaseUrl}api/broadband/subscription`;

  return async (dispatch) => {
    if (token == null || token === "") {
      return;
    }

    const headers = {
      ...API_CONST.AUTHORIZATION(token),
      ...API_CONST.X_COUNTRY_CODE,
    };

    dispatch(broadbandActions.SUBSCRIPTION_REQUEST());

    try {
      const response = await fetch(url, FETCH_OPTIONS.postData(body, headers));
      response
        .json()
        .then((data) => {
          if (response.status !== 200) return;
          if (data?.statusCode === "3001" || data?.statusCode === "3004") {
            dispatch(broadbandActions.SUBSCRIPTION_FAILED());
            history.push(APP_ROUTES.REVIEW_PAY_RESPONSE, {
              responseDetails: {
                isError: 0,
                title: APP_RESPONSE.RESPONSE_FAILURE.title,
                description: data?.message,
              },
            });
          } else {
            dispatch(broadbandActions.SUBSCRIPTION_SUCCESS());
            history.push(APP_ROUTES.REVIEW_PAY_RESPONSE, {
              responseDetails: {
                isError: body.payment_method === "momo" ? 2 : 1,
                title:
                  body.payment_method === "momo"
                    ? APP_RESPONSE.RESPONSE_SUCCESS_MOMO.title
                    : APP_RESPONSE.RESPONSE_SUCCESS.title,
                description:
                  body.payment_method === "momo"
                    ? APP_RESPONSE.RESPONSE_SUCCESS_MOMO.description
                    : APP_RESPONSE.RESPONSE_SUCCESS.description,
                success: true,
              },
            });
          }
        })
        .catch((_) => {
          dispatch(broadbandActions.SUBSCRIPTION_FAILED());
          history.push(APP_ROUTES.REVIEW_PAY_RESPONSE, {
            responseDetails: {
              isError: 0,
              title: APP_RESPONSE.RESPONSE_FAILURE.title,
              description: APP_RESPONSE.RESPONSE_FAILURE.description,
            },
          });
        });
    } catch (_) {
      dispatch(broadbandActions.SUBSCRIPTION_FAILED());
      history.push(APP_ROUTES.REVIEW_PAY_RESPONSE, {
        responseDetails: {
          isError: 0,
          title: APP_RESPONSE.RESPONSE_FAILURE.title,
          description: APP_RESPONSE.RESPONSE_FAILURE.description,
        },
      });
    }
  };
};

export const clearBroadbandBalances = () => {
  return (dispatch) => {
    dispatch(broadbandActions.CLEAR());
  };
};
