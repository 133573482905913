/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: false,
  error_msg: "",
  is_valid_msisdn: false,
  isError: false,
};

const validateMsisdnSlice = createSlice({
  name: REDUCER.VALIDATE_MSISDN,
  initialState,
  reducers: {
    REQUEST(state) {
      state.loading = true;
      state.isError = false;
    },
    VALIDATE_MSISDN_SUCCESS(state, action) {
      state.loading = false;
      state.error_msg = "";
      state.is_valid_msisdn = action.payload.success;
      state.isError = false;
    },
    VALIDATE_MSISDN_FAILURE(state, action) {
      state.loading = false;
      state.error_msg = action.payload.message;
      state.is_valid_msisdn = action.payload.success;
      state.isError = true;
    },
    VALIDATE_MSISDN_CLEAR(state) {
      state.loading = false;
      state.error_msg = "";
      state.is_valid_msisdn = false;
      state.isError = false;
    },
  },
});

export const validateMsisdnAction = validateMsisdnSlice.actions;

export default validateMsisdnSlice;
