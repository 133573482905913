/* istanbul ignore file */
import { loginActions } from "./loginSlice";
import { authActions } from "../auth/authSlice";
import {
  API_CONST,
  FETCH_OPTIONS,
  GENERAL,
} from "../../../constants/appConstants";
import { otpVerified } from "../../../utils/trackerFunctions";
import { SETUP_CONSTANT } from "../../../config/endPointsConfig";
import { setUser } from "../auth/authActions";

const getOtp = (msisdn) => {
  return async (dispatch) => {
    dispatch(loginActions.LOGIN_REQUEST());

    const url = `${SETUP_CONSTANT.endpoints.auth}otp`;
    const headers = {
      ...API_CONST.X_COUNTRY_CODE,
    };

    const response = await fetch(
      url,
      FETCH_OPTIONS.postData(
        {
          msisdn: msisdn,
        },
        headers
      )
    );
    response
      .json()
      .then((res) => {
        if (response.status === 200 && !res.isNotAllowed) {
          dispatch(loginActions.LOGIN_SUCCESS(res));
          // analytics otp sent success
          // otpSentSuccess();
        } else {
          if (response.status === 400) {
            dispatch(loginActions.LOGIN_FAILED(GENERAL.OTP_TIMEOUT_MESSAGE));
          }
          dispatch(loginActions.LOGIN_FAILED(res.data.message));
        }
      })
      .catch((_e) => {
        dispatch(loginActions.LOGIN_FAILED(GENERAL.LOGIN_ERROR_MESSAGE));
        // otpSentFail();
      });
  };
};

const requestToken = (payload) => {
  return async (dispatch) => {
    dispatch(loginActions.LOGIN_REQUEST());
    const url = `${SETUP_CONSTANT.endpoints.transaction}requestToken`;
    const headers = {
      ...API_CONST.X_COUNTRY_CODE,
      ...API_CONST.PLATFORM,
      ...API_CONST.VERSION,
      ...API_CONST.OS,
    };

    const tokenResponse = await fetch(
      url,
      FETCH_OPTIONS.postData(
        {
          customer_id: payload.msisdn,
          scope: "NextGenAPI.Access offline_access",
          grant_type: "password",
          client_id: "MyMTN",
          client_secret: "secret",
          otp_or_password: payload.otp,
        },
        headers
      )
    );
    tokenResponse
      .json()
      .then(async (res) => {
        if (res.access_token) {
          //analitycs
          // firstUserTrack([
          //   {
          //     first_user: res.is_first_onboard,
          //     msisdn: payload.msisdn,
          //     tracker: store.getState().config,
          //   },
          // ]);
          // otpVerified([
          //   {
          //     msisdn: payload.msisdn,
          //     tracker: store.getState().config,
          //   },
          // ]);
          otpVerified([{ msisdn: payload.msisdn }]);
          const _url = `${SETUP_CONSTANT.endpoints.transaction}customerProfile`;
          const response = await fetch(
            _url,
            FETCH_OPTIONS.getData(API_CONST.AUTHORIZATION(res.access_token))
          );
          response
            .json()
            .then((_r) => {
              const userObj = {
                ...res,
                ..._r,
              };
              dispatch(loginActions.TOKEN_SUCCESS(res.access_token));
              dispatch(authActions.AUTH_SUCCESS(userObj));
            })
            .catch((_e) => {
              dispatch(loginActions.LOGIN_FAILED(GENERAL.LOGIN_ERROR_MESSAGE));
            });
        } else {
          dispatch(loginActions.LOGIN_FAILED(GENERAL.LOGIN_ERROR_MESSAGE));
        }
      })
      .catch((_e) => {
        dispatch(loginActions.LOGIN_FAILED(GENERAL.LOGIN_ERROR_MESSAGE));
      });
  };
};

const getCustomerProfile = (user, getAccessTokenSilently) => {
  return async (dispatch) => {
    const token = await getAccessTokenSilently();

    if (token == null || token === "") {
      return;
    }

    if (user.puk !== "") {
      return;
    }

    const url = `${SETUP_CONSTANT.endpoints.auth}customerProfile`;

    const headers = {
      ...API_CONST.X_COUNTRY_CODE,
      ...API_CONST.AUTHORIZATION(token),
    };

    const response = await fetch(url, FETCH_OPTIONS.getData(headers));
    response
      .json()
      .then((res) => {
        dispatch(setUser({ ...user, puk: res?.primary_number?.puk }));
      })
      .catch((e) => {
        dispatch(loginActions.LOGIN_FAILED(e.message));
      });
  };
};

export const getCustomerProfileWithToken = (accessToken) => {
  return async (dispatch) => {
    if (accessToken == null || accessToken === "") {
      return;
    }

    const url = `${SETUP_CONSTANT.endpoints.transaction}customerProfile`;

    const headers = {
      ...API_CONST.X_COUNTRY_CODE,
      ...API_CONST.AUTHORIZATION(accessToken),
    };

    const response = await fetch(url, FETCH_OPTIONS.getData(headers));
    response
      .json()
      .then((res) => {
        const userObj = {
          access_token: accessToken,
          ...res,
        };

        dispatch(authActions.AUTH_SUCCESS(userObj));
      })
      .catch((e) => {
        dispatch(loginActions.LOGIN_FAILED(e.message));
      });
  };
};

const getAuth0Token = (getAccessTokenSilently) => {
  return async (dispatch) => {
    try {
      const _token = await getAccessTokenSilently();
      dispatch(loginActions.TOKEN_SUCCESS(_token));
      dispatch(getCustomerProfileWithToken(_token));
    } catch (e) {
      console.log("Auth0 : An error occured while getting user token", e);
    }
  };
};

const getSaveCustomerProfileFromAuth0 = (getAccessTokenSilently, auth0User) => {
  return async (dispatch) => {
    try {
      const tk = await getAccessTokenSilently();
      dispatch(loginActions.TOKEN_SUCCESS(tk));

      function saveCustomerProfileFromAuth0(_tk) {
        let _userObj = auth0User["https://mymtn.com/userMetaData"];
        if (
          _userObj?.length === 0 ||
          _userObj === null ||
          _userObj === undefined
        ) {
          dispatch(getAuth0Token(getAccessTokenSilently));
        } else {
          let localDate = new Date(_userObj?.kyc?.simRegDate);
          const userObject = {
            access_token: _tk,
            first_name: _userObj?.kyc?.givenName,
            last_name: _userObj?.kyc?.fullName,
            primary_number: {
              subscription_type: {
                name: _userObj?.kyc?.businessType,
              },
              msisdn: auth0User?.name?.replace("+", ""),
            },
            puk: _userObj?.kyc?.puk ?? "",
            plan: _userObj?.kyc?.currentPlan ?? "",
            user_id: _userObj?.kyc?.mtnUserId ?? "",
            sim_registration_date: localDate?.toDateString() ?? "",
          };
          dispatch(authActions.AUTH_SUCCESS(userObject));
        }
      }

      saveCustomerProfileFromAuth0(tk);
    } catch (e) {
      console.log("Auth0 : An error occured while getting user token", e);
    }
  };
};

const clearLogin = () => (dispatch) => {
  dispatch(loginActions.OTP_RESET());
};

export {
  getOtp,
  clearLogin,
  requestToken,
  getSaveCustomerProfileFromAuth0,
  getAuth0Token,
  getCustomerProfile,
};
