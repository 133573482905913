//  ***** Disclaimer *****
//  This file contains the style stettings for use in the project.
//  You can create more of these files and link them in the themeSetup.js
//  uses f => factor values that can change but is ussually set on
//  *********************

const generic = {
  color: {
    primary: {
      bg: "#333", //black
      fg: [
        "#ffffff", //white
        "#cccccc", //middle_grey
      ],
    },
    secondary: {
      bg: "#4C4C4C", //dark grey
      fg: [
        "#4C4C4C", //white
      ],
    },
    accent: {
      bg: "#FFCB05", //yellow
      fg: [
        "#333333", //grey
        "#333", //black
        "#333", //black
        "#cccccc", //middle_grey
        "#333", //deep_blue
        "#333", //light_blue
        "#333", //bonus_blue
      ],
    },
    light: {
      bg: "#ffffff", //white
      fg: [
        "#333333", //grey
        "#4C4C4C", //dark grey
        "#D9D9D9", //mid-grey
      ],
    },
    light_grey: {
      bg: "#E5E5E5", //light grey
      fg: [
        "#333333", //dark grey
        "#C9CACA", //button-disabled text
      ],
    },
    grey: {
      bg: "#EEEEEE", //grey
      fg: [
        "#333333", //dark grey
        "#DDDDDD", //component-grey
        "#F6F6F6", //curve-grey
        "#6F91A5", //blue-grey
      ],
    },
    middle_grey: {
      bg: "#cccccc", //middle grey
      fg: [
        "#333", //black
        "#333333", //dark grey
      ],
    },
    dark_grey: {
      bg: "#4C4C4C", //dark grey
      fg: [
        "#ffffff", //white
        "#666666", //darker_grey
      ],
    },
    error: {
      bg: "#ffffff", //white
      fg: [
        "#BE1E50", //red
        "#DC6428", //warning-orange
      ],
    },
  },
  size: {
    f0: "0px",
    f1: "8px",
    f2: "10px",
    f3: "12px",
    f4: "14px",
    f5: "16px",
    f6: "18px",
    f7: "20px",
    f8: "24px",
    f9: "28px",
    f10: "32px",
  },
  weight: {
    f0: 0,
    f1: 100,
    f2: 200,
    f3: 300,
    f4: 400,
    f5: 500,
    f6: 600,
    f7: 700,
    f8: 800,
    f9: 900,
    f10: 1000,
  },
  radius: {
    f0: "0px",
    f1: "2px",
    f2: "4px",
    f3: "8px",
    f4: "16px",
    f5: "24px",
    f6: "32px",
    f7: "40px",
    f8: "48px",
    f9: "56px",
    f10: "64px",
  },
  spacing: {
    f0: "0px",
    f1: "2px",
    f2: "4px",
    f3: "8px",
    f4: "16px",
    f5: "24px",
    f6: "32px",
    f7: "40px",
    f8: "48px",
    f9: "56px",
    f10: "64px",
  },
  font_family: {},
  image: {},
};

export default generic;
