/* istanbul ignore file */
import { SETUP_CONSTANT } from "../../../config/endPointsConfig";
import { API_CONST, FETCH_OPTIONS } from "../../../constants/appConstants";
import { validateMsisdnAction } from "./validateMsisdnSlice";

export const validateMsisdn = (msisdn, setHasSelectedFor) => {
  const url = `${SETUP_CONSTANT.endpoints.transaction}isMTNCustomer`;
  const headers = {
    ...API_CONST.X_COUNTRY_CODE,
  };

  return async (dispatch) => {
    dispatch(validateMsisdnAction.REQUEST());
    try {
      const response = await fetch(
        url,
        FETCH_OPTIONS.postData({ customer_id: msisdn }, headers)
      );

      response.json().then((data) => {
        if (response.status === 200) {
          if (data === true) {
            dispatch(validateMsisdnAction.VALIDATE_MSISDN_SUCCESS(data));
            setHasSelectedFor(true);
          } else {
            dispatch(
              validateMsisdnAction.VALIDATE_MSISDN_FAILURE({
                message: "Kindly enter a valid MTN number",
                success: false,
              })
            );
          }
        } else {
          dispatch(
            validateMsisdnAction.VALIDATE_MSISDN_FAILURE({
              message: "Something happened!",
              success: false,
            })
          );
        }
      }).catch = (e) => {
        dispatch(
          validateMsisdnAction.VALIDATE_MSISDN_FAILURE({
            message: e.message,
            success: false,
          })
        );
      };
    } catch (ex) {
      dispatch(
        validateMsisdnAction.VALIDATE_MSISDN_FAILURE({
          message: ex.message,
          success: false,
        })
      );
    }
  };
};

export const clearValidate = () => {
  return (dispatch) => {
    dispatch(validateMsisdnAction.VALIDATE_MSISDN_CLEAR());
  };
};
