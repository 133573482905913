/* istanbul ignore file */
import { productCatalogActions } from "./productCatalogSlice";
import {
  API_CONST,
  ENVIRONMENTS,
  FETCH_OPTIONS,
  RECOMMENDED_BUNDLE_LIST,
  SUBSCRIPTION_TYPES,
} from "../../../constants/appConstants";
import { currenEnv, SETUP_CONSTANT } from "../../../config/endPointsConfig";

let catBundles = {};
let dataCategories = [];

const getPrepaidProductsList = (data) => {
  let prepaidProductsList = [];

  let regular = [];
  let others = [];

  if (data.length > 0) {
    prepaidProductsList = data.map((i) => {
      catBundles[i.name] = i.categories || [];
      regular = catBundles["Data"]?.filter((x) => x.id <= 2);
      others = catBundles["Data"]?.filter((x) => x.id >= 3 && x.id < 6);

      //returns a list of products
      return {
        id: i.id,
        index: i.index,
        name: i.name,
        description: i.description,
        icon: i.icon,
      };
    });

    dataCategories = [
      ...regular,
      {
        name: "Others",
        description: "Videos, Midnight and Krokroko",
        bundle_array: others,
        meta: {
          flex_information: {
            category: {
              name: "",
            },
          },
        },
      },
    ];
  }

  return [
    ...prepaidProductsList,
    {
      description: "Packages curated Just4U based on your usage patterns",
      icon: "just4u",
      name: "Just4U",
    },
  ];
};

const getPostpaidProductsList = (list = []) => {
  const data = list[0]?.categories?.find((x) =>
    x?.name?.toLowerCase()?.includes("data")
  );
  //   const postpaidList = list?.find((x) =>
  //     x?.name?.toLowerCase()?.includes("post")
  //   );

  return [data];
  //   postpaidList?.categories
  //     ? [data, ...postpaidList?.categories]
  //     : [data];
};

const getRecommendedBundles = (data) => {
  let _recommendedBundles = [];
  let dataBundles = data.filter((i) => i.name.toLowerCase() === "data");

  if (dataBundles.length > 0) {
    //get 3ghc Data bundle
    let ghc_3_data_bundle = dataBundles[0].categories[0].bundles.filter(
      (i) =>
        i.nact_code.toLowerCase() === RECOMMENDED_BUNDLE_LIST.ract_data_ghc_3
    );
    if (ghc_3_data_bundle !== undefined || ghc_3_data_bundle !== null) {
      _recommendedBundles.push(ghc_3_data_bundle);
    }
  }
  return _recommendedBundles;
};

export const getProductCatalog = (
  getAccessTokenSilently = () => {},
  subscriptionType = ""
) => {
  const url = `${SETUP_CONSTANT.endpoints.shop}fetchCatalogue/gha`;
  return async (dispatch) => {
    dispatch(productCatalogActions.REQUEST());

    const token = await getAccessTokenSilently();

    if (token == null || token === "") {
      return;
    }

    const headers = {
      ...API_CONST.AUTHORIZATION(token),
      ...API_CONST.X_COUNTRY_CODE,
    };
    try {
      const response = await fetch(url, FETCH_OPTIONS.getData(headers));
      response
        .json()
        .then((data) => {
          const recommendedBundles = getRecommendedBundles(data);
          const listOfProducts =
            subscriptionType.toLowerCase() === SUBSCRIPTION_TYPES.POSTPAID
              ? getPostpaidProductsList(data)
              : getPrepaidProductsList(data);
          let modifiedPayload = {
            recommendedBundles: recommendedBundles,
            listOfProducts: listOfProducts,
            catalog: data,
            dataCategories: dataCategories,
          };
          dispatch(productCatalogActions.SUCCESS(modifiedPayload));
        })
        .catch((e) => {
          dispatch(productCatalogActions.FAILED({ message: e.message }));
        });
    } catch (error) {}
  };
};

export const clearProductCatalog = () => {
  return async (dispatch) => {
    dispatch(productCatalogActions.CLEAR());
  };
};
