/* istanbul ignore file */
import { upgradeActions } from "./upgradeNotificationSlice";

export const dismissUpgrade = () => {
  return (dispatch) => {
    dispatch(upgradeActions.TOGGLE());
  };
};

export const resetUpgrade = () => {
  return (dispatch) => {
    dispatch(upgradeActions.RESET_NOTIFICATION());
  };
};
