/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { REDUCER } from "../../../constants/appConstants";

const initialState = {
  loading: false,
  email: "",
  whatsapp_contact: "",
  voice_contact: "",
  sms_contact: "",
  tall_free_contact: "",
  isError: false,
  error_message: "",
};

const helpSlice = createSlice({
  name: REDUCER.HELP,
  initialState,
  reducers: {
    HELP_REQUEST(state) {
      state.loading = true;
    },
    HELP_SUCCESS(state, action) {
      state.loading = false;
      state.email = action.payload.email;
      state.whatsapp_contact = action.payload.whatsapp_contact;
      state.voice_contact = action.payload.voice_contact;
      state.sms_contact = action.payload.sms_contact;
      state.tall_free_contact = action.payload.tall_free_contact;
      state.isError = false;
      state.error_message = "";
    },
    HELP_FAILED(state, action) {
      state.loading = false;
      state.email = "";
      state.whatsapp_contact = "";
      state.voice_contact = "";
      state.sms_contact = "";
      state.tall_free_contact = "";
      state.isError = true;
      state.error_message = action.message;
    },
  },
});

export const helpActions = helpSlice.actions;

export default helpSlice;
